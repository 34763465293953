import Label from "components/Label/Label";
import React, { FC, Fragment, useState, useEffect, useRef } from "react";
import { Route, Redirect, useHistory, Link, useParams } from 'react-router-dom';
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import Textarea from "shared/Textarea/Textarea";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ReCAPTCHA from "react-google-recaptcha";
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, PinterestShareButton, LinkedinShareButton, EmailShareButton, FacebookMessengerShareButton } from "react-share";
import { FacebookIcon, TwitterIcon, WhatsappIcon, PinterestIcon, LinkedinIcon, EmailIcon } from "react-share";

export interface AccountPageProps {
  className?: string;
  DefaultUserID?: string;
  autoFocus?: boolean;
}

 
const AccountPageLeftMenu = () => {
  return (
    <></>
  )
}
const AccountPageMidSection = () => {
  return (
    <div className="right-user-details">
    <h4>Hi Brian Rafferty</h4>
    <div className="mid-list-menu">
      <ul>
        <li><a href=""><i className="las la-photo-video"></i> KUALA LUMPUR, MALAYSIA</a></li>
        <li><a href=""><i className="las la-photo-video"></i> EMAIL VERIFIED</a></li>
        <li><a href=""><i className="las la-photo-video"></i> PHONE VERIFIED</a></li>
      </ul>
    </div>

    <div className="mt-5 mb-5 from-edit-click">
      <div className="line"></div>
      <label></label>
      <div className="flex justify-between"><span>I'm Guest :) </span> <a href="">Edit</a></div>
    </div>

    <div className="mt-5 mb-5 from-edit-click">
      <h4>PERSONAL INFO</h4>
      <label className="sm-text">Your name </label>
      <div className="flex justify-between"><span className="bold-text">Brian Rafferty</span> <a href="">Edit</a></div>
      <div className="line-mid mt-5 mb-5"></div>
    </div>

    <div className="mt-5 mb-5 from-edit-click">
      <label className="sm-text">Date of birth</label>
      <div className="flex justify-between"><span className="bold-text">05 / 06 / 1985</span> <a href="">Edit</a></div>
      <div className="line-mid mt-5 mb-5"></div>
    </div>

    <div className="mt-5 mb-5 from-edit-click">
      <label className="sm-text">Gender</label>
      <div className="flex justify-between"><span className="bold-text">Female</span> <a href="">Edit</a></div>
      <div className="line-mid mt-5 mb-5"></div>
    </div>

    <div className="mt-5 mb-5 from-edit-click">
      <label className="sm-text">Location</label>
      <div className="flex justify-between"><span className="bold-text">Kuala Lumpur, Malaysia</span> <a href="">Edit</a></div>
      <div className="line-mid mt-5 mb-5"></div>
    </div>

    <div className="mt-5 mb-5 from-edit-click">
      <h4>LOGIN METHODS</h4>
      <h5>Email & phone number</h5>
      <div className="socal-list-nav gap-5">
        <div className="list-socal">
          <div>
            <div className="flex gap-0 flex-direction-column">
              <div className="flex gap-2 items-center"><span className="btn-sm-blu-light"><i className="las la-envelope"></i> </span> <span>Email</span> </div>
              <div><a href="" className="bold-black-an">brianrafferty88@gmail.com</a>
              </div>
            </div>
            <div><a href="">Edit</a> <span className="btn-ch-sm"><i className="las la-check"></i></span></div>
          </div>
        </div>

        <div className="list-socal">
          <div>
            <div className="flex gap-0 flex-direction-column">
              <div className="flex gap-2 items-center"><span className="btn-sm-blu-light"><i className="las la-envelope"></i> </span> <span>Phone number</span> </div>
              <div><a href="" className="bold-black-an">+6016 263 4104</a>
              </div>
            </div>
            <div><a href="">Edit</a> <span className="btn-ch-sm"><i className="las la-check"></i></span></div>
          </div>
        </div>
      </div>
    </div>
    <div className="line-mid mt-5 mb-5"></div>

    <div className="mt-5 mb-5 from-edit-click">
      <h5>Social media</h5>
      <div className="socal-list-nav gap-5">
        <div className="list-socal">
          <div>
            <div className="flex gap-0 flex-direction-column">
              <div className="flex gap-2 items-center"><span className="btn-sm-blu-light"><svg width="800" height="800" viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_9_2)">
                  <path d="M400 800C620.914 800 800 620.914 800 400C800 179.086 620.914 0 400 0C179.086 0 0 179.086 0 400C0 620.914 179.086 800 400 800Z" fill="#273B7A" />
                  <path d="M790.998 484.633L546.433 240.064L342.537 256.339L238.384 351.417L251.75 558.241L483.316 791.283C636.922 758.736 757.947 638.047 790.998 484.633Z" fill="#121149" />
                  <path d="M408.725 363.233V439.881H528.595C511.595 494.75 460.458 534.605 400.004 534.605C325.664 534.605 265.397 474.341 265.397 399.997C265.394 325.659 325.658 265.392 399.998 265.392C434.74 265.392 466.304 278.669 490.186 300.277L546.431 240.061C507.848 204.719 456.448 183.137 399.998 183.137C280.229 183.137 183.136 280.228 183.136 400C183.136 519.772 280.229 616.862 400 616.862C519.77 616.862 616.862 519.77 616.862 400C616.862 387.453 615.704 375.189 613.644 363.233H408.725Z" fill="#4285F4" />
                  <path d="M400 265.395C434.742 265.395 466.306 278.672 490.188 300.28L546.433 240.064C507.85 204.719 456.45 183.137 400 183.137C316.942 183.137 244.827 229.85 208.398 298.416L274.58 351.417C294.083 301.12 342.814 265.395 400 265.395Z" fill="#EA4335" />
                  <path d="M265.399 400C265.399 382.847 268.73 366.505 274.581 351.419L208.4 298.417C192.299 328.725 183.139 363.288 183.139 400.002C183.139 436.884 192.375 471.603 208.616 502.011L274.405 448.098C268.674 433.139 265.399 416.97 265.399 400Z" fill="#FBBC05" />
                  <path d="M474.836 511.792C453.426 526.155 427.722 534.606 400.004 534.606C342.631 534.606 293.776 498.65 274.401 448.095L208.612 502.008C245.115 570.344 317.114 616.861 399.998 616.861C453.828 616.861 503.034 597.206 540.947 564.739L474.836 511.792Z" fill="#34A853" />
                  <path d="M616.863 400C616.863 387.453 615.705 375.189 613.644 363.233H408.725V439.881H528.595C519.391 469.594 500.119 494.828 474.836 511.792L540.949 564.741C587.394 524.964 616.863 465.942 616.863 400Z" fill="#4285F4" />
                </g>
                <defs>
                  <clipPath id="clip0_9_2">
                    <rect width="800" height="800" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              </span> <span>Google</span> </div>
              <div><a href="" className="bold-black-an">brianrafferty88@gmail.com</a>
              </div>
            </div>
            <div><a href="">Link</a> <span className="btn-ch-sm"><i className="las la-check"></i></span></div>
          </div>
        </div>

        <div className="list-socal">
          <div>
            <div className="flex gap-0 flex-direction-column">
              <div className="flex gap-2 items-center"><span className="btn-sm-blu-light"><svg width="669" height="664" viewBox="0 0 669 664" fill="none" xmlns="http://www.w3.org/2000/svg">
                <ellipse cx="334.5" cy="332" rx="334.5" ry="332" fill="white" />
                <path d="M333.333 0C150 0 0 149.667 0 334.001C0 500.667 122 639.001 281.333 664.001V430.667H196.667V334.001H281.333V260.333C281.333 176.667 331 130.667 407.333 130.667C443.667 130.667 481.667 137 481.667 137V219.333H439.667C398.333 219.333 385.333 245 385.333 271.334V334.001H478L463 430.667H385.333V664.001C463.88 651.594 535.407 611.517 586.997 551.001C638.587 490.487 666.847 413.521 666.667 334.001C666.667 149.667 516.667 0 333.333 0Z" fill="#3B5998" />
              </svg>
              </span> <span>Phone number</span> </div>
              <div><a href="" className="bold-black-an">+6016 263 4104</a>
              </div>
            </div>
            <div><a href="">Link</a> <span className="btn-ch-sm"><i className="las la-check"></i></span></div>
          </div>
        </div>
      </div>

    </div>

  </div>
  )
}
const AccountPage: FC<AccountPageProps> = ({
  className = "",
  DefaultUserID = localStorage.getItem("user_id") ? localStorage.getItem("user_id") : "",
  autoFocus = false
}) => {

  return (
    <div className="bgcolor-light pb-14 pt-10">
      <div className="container">

        <div className="row-profile  mt-5 mb-5">
         
        <CommonLayout/>
        {AccountPageMidSection ()}
         
        </div>
      </div>
    </div>
  );
};

export default AccountPage;
