import React, { Fragment, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import { FC } from "react";
import ClearDataButton from "./ClearDataButton";

export interface GuestsInputProps {
  defaultValue: {
    guestAdults?: number;
    guestChildren?: number;
    guestInfants?: number;
    guestPets?: number;
  };
  onChange?: (data: GuestsInputProps["defaultValue"]) => void;
  fieldClassName?: string;
  MaxGuests?:number;
  MaxAdults?:number;
  MaxChilds?:number;
}

const GuestsInput: FC<GuestsInputProps> = ({
  defaultValue,
  onChange,
  fieldClassName = "[ nc-hero-field-padding ]",
  MaxGuests,
  MaxAdults,
  MaxChilds
}) => {
  const [guestAdultsInputValue, setGuestAdultsInputValue] = useState(
    defaultValue.guestAdults || 0
  );
  const [guestChildrenInputValue, setGuestChildrenInputValue] = useState(
    defaultValue.guestChildren || 0
  );
  const [guestInfantsInputValue, setGuestInfantsInputValue] = useState(
    defaultValue.guestInfants || 0
  );
  const [PetsInputValue, setPetsInputValue] = useState(
    defaultValue.guestPets || 0
  );


  useEffect(() => {
    setGuestAdultsInputValue(defaultValue.guestAdults || 0);
    setGuestChildrenInputValue(defaultValue.guestChildren || 0);
    setGuestInfantsInputValue(defaultValue.guestInfants || 0);
    setPetsInputValue(defaultValue.guestPets || 0);
  }, [defaultValue]);

  useEffect(() => {
    if (onChange) {
      onChange({
        guestAdults: guestAdultsInputValue,
        guestChildren: guestChildrenInputValue,
        guestInfants: guestInfantsInputValue,
        guestPets: PetsInputValue,
      });
    }
  }, [guestAdultsInputValue, guestChildrenInputValue, guestInfantsInputValue]);

  const totalGuests = guestChildrenInputValue + guestAdultsInputValue + guestInfantsInputValue;
   
  return (
    <Popover className="flex relative [ nc-flex-1 ]">
      {({ open }) => (
        <>
          <Popover.Button
            className={`flex text-left w-full flex-shrink-0 items-center ${fieldClassName} space-x-3 focus:outline-none cursor-pointer ${
              open ? "shadow-2xl rounded-full dark:bg-neutral-800" : ""
            }`}
          >
            
            <div className="flex-grow">
              <span className="block xl:text-sm font-semibold">
                {totalGuests || ""} Guests
              </span>
               
              {!!totalGuests && open && (
                <ClearDataButton
                  onClick={() => {
                    setGuestAdultsInputValue(0);
                    setGuestChildrenInputValue(0);
                    setGuestInfantsInputValue(0);
                    setPetsInputValue(0);
                  }}
                  
                />
              )}
             
            </div>
            <i className="ml-3 las la-arrow-right text-xl"></i>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute right-0 z-10 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl">
              <NcInputNumber
                className="w-full"
                defaultValue={guestAdultsInputValue}
                onChange={(value) => setGuestAdultsInputValue(value)}
                max={MaxAdults}
                min={1}
                label="Adults"
                desc="Ages 13 or above"
              />
              <input type={"hidden"} id="guest" name="guest" value={guestAdultsInputValue} />
              <NcInputNumber
                className="w-full mt-6"
                defaultValue={guestChildrenInputValue}
                onChange={(value) => setGuestChildrenInputValue(value)}
                max={MaxChilds}
                label="Children"
                desc="Ages 2–12"
              />
              <input type={"hidden"} id="child" name="child" value={guestChildrenInputValue} />
              <NcInputNumber
                className="w-full mt-6"
                defaultValue={guestInfantsInputValue}
                onChange={(value) => setGuestInfantsInputValue(value)}
                max={4}
                label="Infants"
                desc="Under 2"
              />
              <input type={"hidden"} id="infants" name="infants" value={guestInfantsInputValue} />
              {/* <NcInputNumber
                className="w-full mt-6"
                defaultValue={PetsInputValue}
                onChange={(value) => setPetsInputValue(value)}
                max={4}
                label="Pets"
                desc="Bringing a service animal?"
              /> */}
              {/* <input type={"hidden"} id="pets" name="pets" value={PetsInputValue} /> */}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default GuestsInput;
