import Label from "components/Label/Label";
import React, { FC, Fragment, useState, useEffect, useRef } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official'
export interface PagePromoCodeProps {
  className?: string;
  DefaultUserID?: string;
}

const PagePromoCode: FC<PagePromoCodeProps> = ({
  className = "",
  DefaultUserID = localStorage.getItem("user_id") ? localStorage.getItem("user_id") : "",
}) => {
  const options = {
    title: {
      text: 'Total Gift Card Value USD$500',
      align: 'left'
    },
    series: [{
      type: 'pie',
      data: [{
        y: 1,
        name: "Used Gift Value",
        color: "#000000"
      }, {
        y: 7,
        name: "Balance Gift Value",
        color: "#f15e75"
      }]
    }]
  }


  const PagePeferralProgram = () => {
    return (
      <div className="right-user-details-card">
        <div className="card">
          <div className="flex justify-between"><h4>Promo Code</h4></div>
          <div className="search-promo-code">
            <input type="" placeholder="Enter promo code and redeem" />
            <span><i className="la la-search"></i></span>
            </div>
            <div className="list-tab-account mt-5">
    <a href="" className="active">Available</a>
    <a href="" className="border-l">Not available</a>
    </div>

    <div className="mb-10 mid-box-refl mt-100 text-center w-1/2 m-lr">
      <h5>FRESH OUT OF PROMO CODES</h5>
      <p>Got a promo code? Enter it above. Or you can invite friends to TinyAway and earn rewards.</p>
    <div className="text-center  mt-5">
                  <a href="" className="btn btn-box-css big-btn">INVITE FRIENDS</a>
                  <a href="" className="btn btn-black big-btn m-8">START EXPLORING</a>
                </div>
    </div>
        </div>

        
       

      </div>
    );
  };

  return (
    <div className="bgcolor-light pb-14 pt-10">
      <div className="container">

        <div className="row-profile  mt-5 mb-5">

          <CommonLayout />
          {PagePeferralProgram()}

        </div>
      </div>
    </div>
  );
};
export default PagePromoCode;
