import React, { FC,useState,useEffect } from "react";
import NavigationItem from "./NavigationItem";
// import { NAVIGATION_DEMO } from "data/navigation";
// import axios from 'axios';  


export interface NavigationProps {
  DefaultUserID?: number;
  menuData:any[];
}
const Navigation: FC<NavigationProps> = ({
  DefaultUserID = localStorage.getItem("user_id") ? localStorage.getItem("user_id") : 0,
  menuData
}) => {
  const [user_id,setUserId]                                   = useState(DefaultUserID);
  const [defaultnegivation,setdefaultnegivation]              = useState(menuData);

  useEffect(() => {
    setUserId(localStorage.getItem("user_id"));
  }, [DefaultUserID]);//
  const isPositive = (value:any) =>{
      if(value.name!='Login' && value.name!='Register' && DefaultUserID!=""){
        return value ;
      }else if(DefaultUserID==""){
        return value ;
      }
  }
  const display = (num:any) =>{
      return num;
  }
  useEffect(() => {
    isPositive(menuData);
  }, [menuData]);//
 useEffect(() => {
  setdefaultnegivation(menuData.map(display).filter(isPositive));
}, []);//NAVIGATION_DEMO.map(display).filter(isPositive)

  return (
    <ul className="nc-Navigation hidden  hide">
      {menuData.map((item) => (
        <NavigationItem key={item.id} menuItem={item} />
      ))}
    </ul>
  );
}
export default Navigation;
