import React from "react";
import { Link } from "react-router-dom";
import Button, { ButtonProps } from "shared/Button/Button";

const ButtonSubmit = () => {
  return (
    <Button
      type="submit"
      className="uppercase w-15-btn bg-primary-6000 hover:bg-primary-700 flex items-center justify-center text-neutral-50 focus:outline-none  py-4 rounded-l h-14" 
    >
      <span className="mr-3">Search</span>
       
    </Button>
  );
};

export default ButtonSubmit;
