import React, { FC,useEffect,useState,useRef } from "react";
//import FacebookLogin from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link } from "react-router-dom";
import axios from 'axios';  
import { Route, Redirect,useHistory} from 'react-router-dom';
import GoogleLogin from 'react-google-login';
import { gapi } from 'gapi-script';
import ReCAPTCHA from "react-google-recaptcha";

const { REACT_APP_SITE_KEY } = process.env;

export interface PageSignUpProps {
  className?: string;
  response?: string;
  autoFocus?: boolean;
}




const loginSocials = [
  // {
  //   name: "Continue with Twitter",
  //   href: "#",
  //   icon: twitterSvg,
  // },
  // {
  //   name: "Continue with Google",
  //   href: "#",
  //   icon: googleSvg,
  // },
];

const PageSignUp: FC<PageSignUpProps> = ({ 
  className = "",
  response,
  autoFocus = false
}) => {
  const history                                     = useHistory();
  const containerRef                                = useRef<HTMLDivElement>(null);
  const inputRef                                    = useRef<HTMLInputElement>(null);
  const [login, setLogin]                           = useState(false);
  const [data, setData]                             = useState({});
  const [picture, setPicture]                       = useState('');
  const [error,setError]                            = useState('');
  const [success,setsuccess]                        = useState('');
  const [details,setDetails]                        = useState({first_name:"",last_name:"",email:"",password:"",google_captcha: ""});
  const [showPopover, setShowPopover]               = useState(autoFocus);
  const [errorMsg, setErrorMsg]                     = useState('');
  const [successMsg, setSuccessMsg]                 = useState('');
  const firstNameRef                                = useRef<HTMLInputElement>(null);
  const lastNameRef                                 = useRef<HTMLInputElement>(null);
  const emailRef                                    = useRef<HTMLInputElement>(null);
  const passwordRef                                 = useRef<HTMLInputElement>(null);
  const clientId                                    = process.env.REACT_APP_GMAIL_CLIENT_ID;
  const facebookAppID                               = process.env.REACT_APP_FACEBOOK_APP_ID;
  const captchaRef                                          = useRef<ReCAPTCHA>(null);

  const submitHandler = (event: React.FormEvent<HTMLFormElement>) =>{
    event.preventDefault();
    const isInvalid = validateInput();
    if (!isInvalid) {
      //setSuccessMsg("You're good to go!");
      axios.post(process.env.REACT_APP_BASE_URL+'/UserRegistration', details)
    .then(function (response) {
      if(response.data.status==200){
        setsuccess('Successfully register');
        setDetails({first_name:"",last_name:"",email:"",password:"",google_captcha:""});
        history.push("/login");
      }else{
        setErrorMsg(response.data.response);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
    } else {
      setSuccessMsg('');
    }
  }
  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);
  useEffect(() => {
    gapi.load("client:auth2",()=>{
      gapi.auth2.init({clientId:clientId})
    })
  },[]);
  const responseFacebook = (response_fb:any) => {
    //console.log(response_fb);
    //setData(response_fb);
    if (response_fb.accessToken) {
      axios.post(process.env.REACT_APP_BASE_URL+'/UserRegistrationbySocial', {
        name        :response_fb.name,
        first_name  :response_fb.first_name,
        last_name   :response_fb.last_name,
        accessToken :response_fb.accessToken,
        email       :response_fb.email,
        userID      :response_fb.userID,
        picture     :response_fb.picture.data.url,
        platform    :"facebook"
      })
    .then(function (response) {
      console.log(response.data.response);
      if(response.data.status==200){
        setsuccess('Successfully register');
        setSuccessMsg('Successfully register');
        //setDetails({first_name:"",last_name:"",email:"",password:""});
        localStorage.setItem("user_id",response.data.user_details.user_id);
        history.push("/");
      }else{
        setError(response.data.response);
        setErrorMsg(response.data.response);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
      setLogin(true);
    } else {
      setLogin(false);
    }
  }
  const responseGoogle = (response_gmail:any) => {
    //console.log(response_gmail);
    if (response_gmail.accessToken) {
      axios.post(process.env.REACT_APP_BASE_URL+'/UserRegistrationbySocial', {
        name        :response_gmail.profileObj.name,
        first_name  :response_gmail.profileObj.givenName,
        last_name   :response_gmail.profileObj.familyName,
        accessToken :response_gmail.accessToken,
        email       :response_gmail.profileObj.email,
        userID      :response_gmail.profileObj.googleId,
        picture     :response_gmail.profileObj.imageUrl,
        platform    :"gmail"
      })
    .then(function (response) {
      console.log(response.data.response);
      if(response.data.status==200){
        setsuccess('Successfully register');
        setSuccessMsg('Successfully register');
        //setDetails({first_name:"",last_name:"",email:"",password:""});
        localStorage.setItem("user_id",response.data.user_details.user_id);
        history.push("/");
      }else{
        setError(response.data.response);
        setErrorMsg(response.data.response);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
      setLogin(true);
    } else {
      setLogin(false);
    }
  }
  const handleInputChange = (event: React.FormEvent<HTMLFormElement>) => {
    const { name, value } = event.currentTarget;
    setDetails(prevDetails => ({
      ...prevDetails,
      [name]: value
    }));
  };
  const validateInput = () => {
    const fields = [
      {
        name: 'fist_name',
        value: details.first_name,
        message: 'First name should not be blank.'
      },
      {
        name: 'last_name',
        value: details.last_name,
        message: 'Last name should not be blank.'
      },
      {
        name: 'email',
        value: details.email,
        message: 'Email should not be blank.'
      },
      {
        name: 'password',
        value: details.password,
        message: 'Password should not be blank.'
      }
    ];
    const isNotFilled = fields.some(field => {
      if (field.value.trim() === '') {
        setErrorMsg(field.message);
        //field.name === 'email' ? emailRef.current.focus() : passwordRef.current.focus();
        return true;
      }
      setErrorMsg('');
      return false;
    });
    return isNotFilled;
  };
  function SetRecaptchaVal(value: any) {
    setDetails({ ...details, google_captcha:value});
  }
  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Tiny House Rentals Australia | Weekend Getaway Sydney &amp; Melbourne</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
      <div className="flex flex-login mt-4">
        <div className="w-1/2">
        <img className="h-full" src="https://pictures.tinyaway.com/filters:format(jpg)/960x720/Stories/Images/2024/06/226/IMG_23791719016086252.jpg"/>
        </div>
        <div className="w-1/2 p-2.5">
        <h2 className="uppercase">  Signup</h2>
        
        <div className="max-w-md mx-auto space-y-6 ">
        
          <div className="grid gap-3 ">
          <FacebookLogin
              appId={facebookAppID}
              autoLoad={false}
              fields="email,name,picture,first_name,last_name"
              scope="public_profile,email"
              callback={responseFacebook}
              version="3.1"
              cookie={false}
              icon="fa-facebook"
              render={renderProps => (
                <a 
                href="#"
                className="facebook-login"
                onClick={renderProps.onClick}
              >
                  <img
                  className="flex-shrink-0"
                  src={facebookSvg}
                  alt="Continue with Facebook"
                />
                   Continue with Facebook 

              </a>
              )}
              />
            <GoogleLogin
              clientId={clientId}
              render={renderProps => (
                <button 
                className="gmail-login"
                onClick={renderProps.onClick} disabled={renderProps.disabled}>
                <img 
                  src={googleSvg}
                  alt="Continue with Google"
                />
                  Continue with Google 
                </button>
              )}
              buttonText="Login"
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              cookiePolicy={'single_host_origin'}
            />
          </div>
          {/* OR */}
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
          
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          {successMsg && 
          <div className="alert alert-success" role="success alert"><span className="las la-check-circle"></span>
          {successMsg}
          </div>}
          {errorMsg && 
          <div className="alert alert-warning" role="warning alert"><span className="las la-exclamation-circle"></span>
          {errorMsg}</div>}
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={submitHandler}>
            <div className="flex gap-2">
            <div className="w-1/2">
          <label className="block">
               
              <Input
                type="text"
                placeholder="FIRST NAME"
                className="mt-1"
                name="first_name"
                id="first_name"
                onChange={event=>setDetails({...details,first_name:event.target.value})}
                ref={firstNameRef}
                autoComplete="off"
                autoFocus={showPopover}
                value={details.first_name}
              />
            </label>
            </div>
            <div className="w-1/2">
            <label className="block">
              
              <Input
                type="text"
                 placeholder="LAST NAME"
                className="mt-1"
                name="last_name"
                id="last_name"
                onChange={event=>setDetails({...details,last_name:event.target.value})}
                ref={lastNameRef}
                autoComplete="off"
                autoFocus={showPopover}
                value={details.last_name}
              />
            </label>
            </div>
            </div>
            <label className="block">
               
              <Input
                type="email"
                 placeholder="EMAIL"
                className="mt-1"
                name="email"
                id="email"
                onChange={event=>setDetails({...details,email:event.target.value})}
                ref={emailRef}
                autoComplete="off"
                autoFocus={showPopover}
                value={details.email}
              />
            </label>
            <label className="block">
              
              <Input 
               placeholder="PASSWORD"
              type="password" 
              className="mt-1"
              name="password"
              id="password"
              value={details.password}
              onChange={event=>setDetails({...details,password:event.target.value})}
              ref={passwordRef}
              autoComplete="off"
              autoFocus={showPopover}
              />
            </label>
            <div>
                <ReCAPTCHA
                    // size="invisible"
                    sitekey={REACT_APP_SITE_KEY!}
                    ref={captchaRef}
                    id="google_captcha"
                    onChange={SetRecaptchaVal}
                  />
                </div>
            <ButtonPrimary type="submit" className="w-1/200 m-auto Source-Sans-3 btn-bold">SIGN UP</ButtonPrimary>
          </form>

          {/* ==== */}
          <div className="flex flex-direction-column gap-3 justify-center text-center password-create-acc">
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Already have an account? {` `}
            <Link to="/login">Sign in</Link>
          </span>
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
  );
};

export default PageSignUp;
