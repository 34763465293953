import React, { FC, Fragment,useState,useEffect,useRef } from "react";
import { Route, Redirect,useHistory,Link,useParams} from 'react-router-dom';
import ButtonPrimary from "shared/Button/ButtonPrimary";
import CommonLayout from "./CommonLayout";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import NcImage from "shared/NcImage/NcImage";
import { Dialog, Transition } from "@headlessui/react";
import axios from 'axios';  
import { Helmet } from "react-helmet";
import ReactPaginate from 'react-paginate';
import { FocusedInputShape } from "react-dates";
import ReservationDateRangeInput from "./ReservationDateRangeInput";
import Input from "shared/Input/Input";


export interface DateRage {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

export interface AccountReservationsProps {
   className?: string;
   DefaultUserID?: string;
 }

 const AccountReservations: FC<AccountReservationsProps> = ({ 
   className = "",
   DefaultUserID = localStorage.getItem("user_id") ? localStorage.getItem("user_id") : "",
 }) => {

   const history                                               = useHistory();
   const [user_id,setUserId]                                   = useState(DefaultUserID);
   const [feature, setFeature]                                 = useState([]); 
   const [isdataLoaded, setIsdataLoaded]                       = useState(false);
   const [isOpenModalAddons, setIsOpenModalAddons]             = useState(false);
   const [bookingcode, setbookingcode]                         = useState("");
   const [totalRecords, setTotalRecords]                       = useState(0);
   const [starLimit, setStarLimit]                             = useState(0);
   const [endLimit, setEndLimit]                               = useState(0);
   const [totalPages, setTotalPages]                           = useState(0);
   const [pageRangeDisplay, setPageRangeDisplay]               = useState(0);
   const [pageNumber, setPageNumber]                           = useState(0);
   const [locationInputValue, setLocationInputValue]           = useState("");
   const [dateFocused, setDateFocused]                         = useState<FocusedInputShape | null>(
     null
   );
   const [dateRangeValue, setDateRangeValue] = useState<DateRage>({
    startDate: null,
    endDate: null,
  });
   useEffect(() => {
    console.log(localStorage.getItem("user_id"));
      setUserId(localStorage.getItem("user_id"));
      if(DefaultUserID==""){
        history.push("/login");
      }
    }, [DefaultUserID]);

    const  PaginatedItems  = (itemsPerPage:any) => {
      // Here we use item offsets; we could also use page offsets
      // following the API or data you're working with.
      const [itemOffset, setItemOffset]                           = useState(0);
      
      //setStarLimit(itemOffset);
      // Simulate fetching items from another resources.
      // (This could be items from props; or items loaded in a local state
      // from an API endpoint with useEffect and useState)
      //console.log("itemOffset"+itemOffset);
      const endOffset = itemOffset + parseInt(itemsPerPage.itemsPerPage);
      //setEndLimit(endOffset);
      console.log(`Loading items from ${itemOffset} to ${endOffset}`);
      //const currentItemsss = items.slice(0, 4);
      const pageCount = Math.ceil(totalRecords / itemsPerPage.itemsPerPage);
      // Invoke when user click to request another page.
      const handlePageClick = (event:any) => {
        const newOffset = (event.selected * itemsPerPage.itemsPerPage) % totalRecords;
        console.log(
          `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
        setStarLimit(newOffset);
        //setEndLimit(newOffset);
      };
      return (
        <>
          
        </>
      );
    }
    
    const handlePageClick = (event:any) => {
      const newOffset = parseInt(event.selected)+1;
      setPageNumber(newOffset);
      //setIsdataLoaded(false);
      // console.log(
      //   `User requested page number ${event.selected}, which is offset ${newOffset}`
      // );
      //setItemOffset(newOffset);
      //setStarLimit(newOffset);
      //setEndLimit(newOffset);
    };

    useEffect(() => {
      //setIsdataLoaded(false);
      axios.get(process.env.REACT_APP_BASE_URL+'getBookings?user_id='+user_id+"&search_by="+locationInputValue
      +"&checkin="+(dateRangeValue.startDate ? dateRangeValue.startDate.format('YYYY-MM-DD'):"")
      +"&checkout="+(dateRangeValue.endDate ? dateRangeValue.endDate.format('YYYY-MM-DD'):"")
      +"&page="+pageNumber)
      .then(res => {
        console.log(res.data);
        setFeature(res.data.bookings);
        setTotalRecords(res.data.total_record);
        setTotalPages(res.data.total_pages);
        setPageRangeDisplay(res.data.page_range_display);
        setIsdataLoaded(true);
      })
  }, [DefaultUserID,pageNumber,locationInputValue,dateRangeValue.startDate,dateRangeValue.endDate]);

  function closeModalViewAddons() {
   setIsOpenModalAddons(false);
 }
 function openModalViewAddons(booking_code:string) {
  axios.get(process.env.REACT_APP_BASE_URL+'getaddons?booking_code='+booking_code+"&user_id="+user_id+"&itemOffset=")
  .then(res => {
      setbookingcode(res.data.addpn_html)
    })
   setIsOpenModalAddons(true);
 }

 const Items = (currentItems: any) => {
  return (
    <>
      {currentItems.map((item:any) => (
          <div>
            <h3>Item #{item}</h3>
          </div>
        ))}
    </>
  );
}
  const renderMotalViewAddons = () => {
   return (
    <div className="right-user-details">
    <h4>Reservations</h4>
    <div className="list-tab-account">
    <a href="" className="active">All bookings</a>
    <a href="">Active</a>
    <a href="">Past</a>
    <a href="">Cancelled</a>
    </div>
    <div className="line-mid mt-5 mb-5"></div>
 
    <div className="flex">
      <div className="w-70">
        <div className="content">
          <h4>TINY HOUSE 17 AT GRAMPIANS EDGE</h4>
          <p>DADSWELLS BRIDGE VIC 3385, AUSTRALIA</p>
          <div className="mb-20 mt-15  flex gap-2 mb-20 mt-15 coll-row">
            <div className="flex gap-2 items-center"><i className="las la-user-edit"></i><span>SUN, 26 MAY 24 — TUE, 28 MAY 24</span></div>
            <div className="flex gap-2 items-center"><i className="las la-user-edit"></i><span>2 PEOPLE </span></div>
            <div className="flex gap-2 items-center"><i className="las la-user-edit"></i><span>1 BED </span></div>
            <div className="flex gap-2 items-center"><i className="las la-user-edit"></i><span>1 BATH</span></div>
          </div>
          
        </div>
        {/* <div className="content mt-10">
        <h4 className="mt-3 font-size-20">ADD ONS</h4>
        <div className="flex flex-row-add-on gap-4 grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-2">
          <div className="flex gap-2">
            <div className="w-70px"><img src="https://pictures.tinyaway.com/filters:format(jpg)/100x100/Listings/Images/2024/08/396/hsorkf72hwfup4dfnuj91724365347810.jpg" /></div>
            <div className="w-100"><b>Pre-order food</b><p>Lunch - Large Pizza Set S$ 13 x 1</p></div>
        </div>
        <div className="flex gap-2">
        <div className="w-70px"><img src="https://pictures.tinyaway.com/filters:format(jpg)/100x100/Listings/Images/2024/08/396/hsorkf72hwfup4dfnuj91724365347810.jpg" /></div>
            <div className="w-100"><b>Pre-order food</b><p>Dinner - Spaghetti set S$ 25 x 2</p></div>
        </div>

        </div>
        </div> */}
        
        <div  className="content mt-10">
          <h4>BOOKING DETAILS</h4>
          <div className="flex gap-20 table-row-2">
            <div className="w-100px">BOOKING CODE </div><div>#87Fw9FsAC </div>
          </div>
          <div className="flex gap-20 table-row-2">
            <div  className="w-100px">DATE </div><div>05 May 24 MYR </div>
          </div>
          <div className="flex gap-20 table-row-2">
            <div  className="w-100px">TOTAL AMOUNT </div><div>1415 </div>
          </div>
          <div className="flex gap-20 table-row-2">
            <div  className="w-100px">PAYMENT METHOD</div><div>Credit Card</div>
          </div>
        </div>
      </div>
      <div className="w-30">
          <div className="right-box-add-on">
              <p className="confirmed">Booking confirmed</p>
              <img src="https://pictures.tinyaway.com/filters:format(jpg)/200x200/Listings/Images/2024/09/952/ksegndmyjunkljeey8hp1725612069256.jpg" />
              <a href="" className="btn btn-box-css">Share booking</a>
              <a href="" className="btn btn-box-theme">Request to canel</a>
          </div>
      </div>
    </div>

    <div className="line-mid mt-5 mb-5"></div>

    <div className="flex">
      <div className="w-70">
        <div className="content">
          <h4>TINY HOUSE 17 AT GRAMPIANS EDGE</h4>
          <p>DADSWELLS BRIDGE VIC 3385, AUSTRALIA</p>
          <div className="mb-20 mt-15  flex gap-2 mb-20 mt-15 coll-row">
            <div className="flex gap-2 items-center"><i className="las la-user-edit"></i><span>SUN, 26 MAY 24 — TUE, 28 MAY 24</span></div>
            <div className="flex gap-2 items-center"><i className="las la-user-edit"></i><span>2 PEOPLE </span></div>
            <div className="flex gap-2 items-center"><i className="las la-user-edit"></i><span>1 BED </span></div>
            <div className="flex gap-2 items-center"><i className="las la-user-edit"></i><span>1 BATH</span></div>
          </div>
          
        </div>
        
        
        <div  className="content mt-10">
          <h4>BOOKING DETAILS</h4>
          <div className="flex gap-20 table-row-2">
            <div className="w-100px">BOOKING CODE </div><div>#87Fw9FsAC </div>
          </div>
          <div className="flex gap-20 table-row-2">
            <div  className="w-100px">DATE </div><div>05 May 24 MYR </div>
          </div>
          <div className="flex gap-20 table-row-2">
            <div  className="w-100px">TOTAL AMOUNT </div><div>1415 </div>
          </div>
          <div className="flex gap-20 table-row-2">
            <div  className="w-100px">PAYMENT METHOD</div><div>Credit Card</div>
          </div>
        </div>
      </div>
      <div className="w-30">
          <div className="right-box-add-on">
              <p className="completed">Booking completed!</p>
              <img src="https://pictures.tinyaway.com/filters:format(jpg)/200x200/Listings/Images/2024/09/952/ksegndmyjunkljeey8hp1725612069256.jpg" />
              <a href="" className="btn btn-box-css">Share booking</a>
              <div className="star flex star-row">
              <i className="la-star las"></i>
              <i className="la-star la-star-half-alt las"></i>
              <i className="la-star la-star-half-alt  las"></i>
              </div>
              <p className="rate-color font-size-20">TAP TO RATE</p>
          </div>
      </div>
    </div>
  </div>
   );
 }; 
  return (
    <div className="bgcolor-light pb-14 pt-10">
      <div className="container">

        <div className="row-profile  mt-5 mb-5">
         
        <CommonLayout/>
        {renderMotalViewAddons ()}
         
        </div>
      </div>
    </div>
  );
};
export default AccountReservations;
