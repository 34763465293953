import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_EXPERIENCES_LISTINGS } from "data/listings";
import { ExperiencesDataType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";

export interface ExperiencesCardProps {
  className?: string;
  ratioClass?: string;
  data?: ExperiencesDataType;
  size?: "default" | "small";
}

const DEMO_DATA: ExperiencesDataType = DEMO_EXPERIENCES_LISTINGS[0];

const ExperiencesCard: FC<ExperiencesCardProps> = ({
  size = "default",
  className = "",
  data = DEMO_DATA,
  ratioClass = "aspect-w-3 aspect-h-2",
}) => {
  const {
    galleryImgs,
    address,
    title,
    listing_name,
    influencer_profile_pic,
    influencer_name,
    href,
    like,
    maxGuests,
    beds,
    bathrooms,
    saleOff,
    isAds,
    price,
    reviewStart,
    reviewCount,
    id,
    currency_code,
    totalplacevisited
  } = data;

  const renderSliderGallery = () => {
    return (
      
      <div className="relative w-full rounded-0xl overflow-hidden">
        <GallerySlider
          uniqueID={`ExperiencesCard-${id}`}
          ratioClass={ratioClass}
          galleryImgs={galleryImgs}
        />
        <BtnLikeIcon isLiked={like} className="absolute right-3 top-5" />
        {saleOff && <SaleOffBadge className="absolute left-3 top-12" />}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "py-4 space-y-4" : "py-3 space-y-2"}>
        <div className="space-y-0">
        <div className="flex items-center space-x-2">
            {isAds && <Badge name="ADS" color="green" />}
            <h2
              className={` font-medium capitalize ${
                size === "default" ? "text-lg" : "text-lg"
              }`}
            >
              <span className="line-clamp-1">{listing_name}</span>
            </h2>
          </div>

          
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            {/* {size === "default" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            )} */}
            <span className="uppercase ">{address}</span>
          </div>

          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
          {/* <i className="las la-hiking text-lg"></i> */}
            <span className="mt-1.5">{totalplacevisited} Places we visited</span>
          </div>
        </div>
        
        {/* <div className="hidden sm:grid grid-cols-3 gap-2">
          <div className="space-y-3">
            <div className="flex items-center space-x-3">
              <i className="las la-bed text-lg"></i>
              <span className="text-sm text-neutral-500 dark:text-neutral-400">
                {bathrooms} bed
              </span>
            </div>
          </div>
          <div className="space-y-3">
            <div className="flex items-center space-x-3">
              <i className="las la-bath text-lg"></i>
              <span className="text-sm text-neutral-500 dark:text-neutral-400">
              {bathrooms} bath
              </span>
            </div>
          </div>
          <div className="space-y-3">
            <div className="flex items-center space-x-3">
              <i className="las la-user text-lg"></i>
              <span className="text-sm text-neutral-500 dark:text-neutral-400">
              {maxGuests} guest
              </span>
            </div>
          </div>
        </div>  */}
      </div>
    );
  };

  return (
    <div
      className={`nc-ExperiencesCard group relative border ${className}`}
      data-nc-id="ExperiencesCard"
    >
      <div className="d-flex-content-img">
          <div className="img-user-icon">
            <img src={influencer_profile_pic}></img>
          </div>
          <div className="text-user-inf">
            <h5><span className="line-clamp-1">Story by {influencer_name}</span></h5>
            <p>{title}</p>
          </div>
        </div>
      <Link to={href}>
        {renderSliderGallery()}
        {renderContent()}
      </Link>
    </div>
  );
};

export default ExperiencesCard;
