import React from "react";
import { DEMO_POSTS } from "data/posts";
import { Helmet } from "react-helmet";
import SectionAds from "./SectionAds";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionMagazine5 from "./SectionMagazine5";
import SectionLatestPosts from "./SectionLatestPosts";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import ButtonPrimary from "shared/Button/ButtonPrimary";
// DEMO DATA
const POSTS = DEMO_POSTS;

// DEMO POST FOR MAGAZINE SECTION
const MAGAZINE1_POSTS = POSTS.filter((_, i) => i >= 0 && i < 8);
//

const BlogPage: React.FC = () => {
  return (
    <div className="nc-BlogPage overflow-hidden relative">

      <Helmet>
        <title>Blog || Booking React Template</title>
      </Helmet>
      <div className=" w-full">
            <div className="blog-banner relative">
              <img className="w-full" src="https://pictures.tinyaway.com/filters:format(jpg)/1760x620/Listings/Images/2024/07/484/uhvmmpb8cpsxeo43uggf1719912591768.jpg" alt=""></img>
              <div className="main-about-banner">
                <div> 
                  <p>FIND INSPIRATION, </p><p>GUIDES AND STORIES FOR WHEREVER YOU'RE GOING</p>
                  <ButtonPrimary type="submit" className="btn-white mt-5">DISCOVER</ButtonPrimary>
                </div>
              </div>

            </div>

          </div>
      {/* ======== BG GLASS ======== */}
      {/* <BgGlassmorphism /> */}
      {/* ======== ALL SECTIONS ======== */}
      {/* ======= START CONTAINER ============= */}
      <div className="container relative bg-gray">
        {/* === SECTION 1 === */}
        <div className="pt-12 pb-16 lg:pb-28">
          <SectionMagazine5 posts={MAGAZINE1_POSTS} />
        </div>

        {/* === SECTION 1 === */}
        {/* <SectionAds /> */}

        {/* === SECTION 8 === */}
        {/* <SectionLatestPosts className="py-16 lg:py-28" /> */}

        {/* === SECTION 1 === */}
       
      </div>
      <SectionSubscribe2 />
    </div>
  );
};

export default BlogPage;
