import React from "react";
import { FC } from "react";
import { NavLink } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
export interface CommonLayoutProps {}

const CommonLayout: FC<CommonLayoutProps> = ({ children }) => {
  return (
    <div className="left-user-details flex-wrap">
    <div className="Avatar-profile">
      <Avatar avatarImage="https://pictures.tinyaway.com/filters:format(jpg)/200x200/Listings/Images/2024/06/2/xwnbnciixvh8goyetvdx1717650186507.jpg"></Avatar>
    </div>
    <h4>Brian Rafferty</h4>
    <a href="/account" className="btn btn-profile"><i className=" las la-edit text-2xl "></i> Edit Profile</a>
    <div className="nav-left-page mt-5">
      <ul>
        <li><a href="/pagepromocode" className="flex gap-2 items-center"><span><i className="las la-photo-video"></i>PROMO CODE </span> <i className="las la-plus"></i></a></li>
        <li><a href="" className="flex gap-2 items-center"><span><i className="las la-photo-video"></i> REWARDS</span></a></li>
        <li><div className="line"></div></li>
        <li><a href="/reservations" className="flex gap-2 items-center"><span><i className="las la-photo-video"></i> RESERVATIONS</span></a></li>
        <li><a href="/wishlists" className="flex gap-2 items-center"><span><i className="las la-photo-video"></i> WISHLISTS</span></a></li>
        <li><a href="/giftcardsredeem" className="flex gap-2 items-center"><span><i className="las la-photo-video"></i> GIFT CARD</span></a></li>
        <li><a href="/Pagereferralprogram" className="flex gap-2 items-center"><span><i className="las la-photo-video"></i> REFERRAL PROGRAM</span></a></li>
        <li><a href="/pagemypost" className="flex gap-2 items-center"><span><i className="las la-photo-video"></i> MY POST</span></a></li>
        <li><div className="line"></div></li>
        <li><a href="/account" className="flex gap-2 items-center"><span><i className="las la-photo-video"></i> EDIT PROFILE</span></a></li>
        <li><a href="" className="flex gap-2 items-center"><span><i className="las la-photo-video"></i> LOGOUT</span></a></li>
      </ul>
    </div>


  </div>
  );
};

export default CommonLayout;
