import React, { FC, ReactNode , useEffect, useState }  from "react";
import { useParams,useLocation  } from 'react-router-dom';
import StayCard from "components/StayCard/StayCard";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import Pagination from "shared/Pagination/Pagination";
import TabFilters from "./TabFilters";
import Heading2 from "components/Heading/Heading2";
import axios from 'axios'; 
import { Helmet } from "react-helmet";


export interface SectionGridFilterCardProps {
  className?: string;
  data?: StayDataType[];
}

const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i < 8);
const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  data = DEMO_DATA,
}) => {
  const [feature, setFeature]                   =   useState([]); 
  const [totalpages, setTotalpages]             =   useState(1); 
  const [totalrecord, setTotalrecord]           =   useState(1); 
  const [currentpageno, setCurrentpageno]       =   useState("1"); 
  const [pagetoptitle, setPagetoptitle]         =   useState([]); 
  const [pagetopDesc, setagetopDesc]            =   useState(""); 
  const [paginationslug, setPaginationslug]     =   useState(""); 
  const [metatitle, setMetatitle]               =   useState(""); 
  const [metadescription, setMetadescription]   =   useState(""); 
  const [metadata, SetMetaData]                 =   useState([]);
  const [isdataLoaded, SetIsdataLoaded]         =   useState(false);
  const sampleLocation                          =   useLocation();

  let search    = window.location.search;
  let params    = new URLSearchParams(search);
  let page      = params.get('page');
  const pageNumber = page || 0;
  const params_state    = useParams();
  const slug            = Object.values(params_state);
  const answer_array = sampleLocation.pathname.split('/');
  let filtervars  = '';

  console.log("slug="+slug[0]+"Slug2="+slug[1]);

  if(slug[0]!=''){
    if(Number(pageNumber) > 0){
      filtervars  = 'state_slug='+slug+'&location='+answer_array[1]+'&page='+pageNumber;
    }else{
      filtervars  = 'state_slug='+slug+'&location='+answer_array[1];
    }
  }
  useEffect(() => {
  axios.get(process.env.REACT_APP_BASE_URL+'/GetAllListing?'+filtervars)
 	 .then(res => {
         setFeature(res.data.listings); 
         setTotalpages(res.data.total_pages); 
         setTotalrecord(res.data.total_record); 
         setPagetoptitle(res.data.page_title); 
         setagetopDesc(res.data.page_description); 
         setPaginationslug(res.data.pagination_slug); 
         setCurrentpageno(pageNumber.toString());
         setMetatitle(res.data.meta_title);
         setMetadescription(res.data.meta_data);
         SetMetaData(res.data.meta_data);
         SetIsdataLoaded(true);
    });   
  },[filtervars]);
  //console.log('user'); 
  //console.log(feature);

  const renderskeletonLoader = () => {
      return (
        <div className="bg-white border relative rounded-2xl custom-loader w-full flex-none-loader">
          <div className="skeleton nc-StayCard group relative border border-neutral-100 rounded-2xl h-1/2 w-full"></div>
          <div className="w-full p-4 space-y-4">
            <div className="border border-neutral-100 group h-3 nc-StayCard relative rounded-2xl skeleton w-2/3 mb-3"></div>
            <div className="border border-neutral-100 group h-4 nc-StayCard relative rounded-2xl skeleton w-1/2"></div>
            <div className="border border-neutral-100 group h-3 nc-StayCard relative rounded-2xl skeleton w-full mb-2 mt-2"></div>
          </div>
            <div className="w-full p-4">
              <div className="gap-2 sm:grid grid-cols-3">      
              <div className="border border-neutral-100 h-6 rounded-2xl skeleton"></div>
              <div className="border border-neutral-100 h-6 rounded-2xl skeleton"></div>
              <div className="border border-neutral-100 h-6 rounded-2xl skeleton"></div>
              </div>
              <div className="skeleton nc-StayCard group relative border border-neutral-100 rounded-2xl mt-5 h-5 w-2/3"></div>
            </div>
        </div>
      );
  };

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <Helmet title={metatitle}></Helmet>
      <div className="flow-root w-full">
        <div className="z-10 lg:-mt-5 w-full">
          <div className="py-10 search-box-text pb-0">
              <h2 className="text-4xl font-semibold">{pagetoptitle}</h2>
              <div dangerouslySetInnerHTML={ {__html: pagetopDesc} } />
          </div>
        </div>  
      </div>
      <Heading2 totalPages={totalrecord} />
      <div className="mb-8 lg:mb-11">
        {/*<TabFilters />*/}
      </div>
      
        {isdataLoaded ?
          <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 G1">
          {feature.map((stay) => (
            <StayCard key={stay['id']} data={stay} />
          ))}
          </div>
          :
          <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 G1">
            {renderskeletonLoader()}
            {renderskeletonLoader()}
            {renderskeletonLoader()}
          </div>
        }
        
        
      
      <div className="flex mt-16 justify-center items-center">
        <Pagination count={totalpages} pageno={pageNumber?.toString()} 
        page_url={paginationslug}
        />
      </div>
      
      <div className="FeaturesHousesText">
          <h4>Features of our Tiny Houses</h4>
          <p>No matter the location of tiny house you are interested in renting from us, you will find the following features in most of the tiny houses, except for a few:</p>
          <ul>
            <li>A kitchenette with all you need to prepare your light meals, including a cookware, portable cooker, cooking utensils, dishes & silverware, cutlery, microwave, and a mini-fridge.</li>
          <li>A bathroom that operates in an ecologically sustainable principle, comprising of a waterless environmentally friendly compost toilet, shower (gas heated for hot showers), hand basin.</li>
         <li>Air conditioning</li>
         <li>A comfy queen sized bed with fresh bed sheets.</li>
         <li>A lounge area to chill and enjoy nature at its best.</li>
          </ul>
          <p>Here at Tiny Away, we also have pet-friendly cabins for guests who would love a vacation with their pets. We encourage guests booking with their pets to inform the host. Our tiny houses are built to give you the best comfort and convenience while escaping from the hectic urban life.</p>
     
          <h4>Why Tiny House Getaway</h4>
          <p>There are various reasons people want to live in a tiny house. These houses are becoming a popular option for so many people. Some of the reasons they are getting so much attention are because they are a sustainable and eco-friendly way to experience nature at its finest. Situated in rural areas, far away from civilization, our tiny houses allow you to have a digital detox and truly enjoy nature while basking in the moment.</p>
          <p>Whether you’re looking for an escape from your usual bustling or maybe you’re someone who loves camping and being outdoor, here at Tiny Away, we have the best option for you to enjoy your lifestyle. Our tiny houses are sure to provide you with a comfortable escape surrounded by the most relaxing natural view.</p>
          <h4>Why Choose Us</h4>
          <p>Here at Tiny Away, we specialise in tiny house rentals, usually small cabins, with almost everything you need to lead a comfortable life. So if you crave a unique holiday experience in a natural environment, where you don’t have to worry about the hectic city life, book a tiny house with us today.</p>
          <p>Check out our variety of tiny houses and make your booking for that wonderful getaway experience.</p>
      </div>
    </div>
  );
};

export default SectionGridFilterCard;
