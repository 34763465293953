import React, { FC, Fragment, useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
import { Tab } from "@headlessui/react"; 
import SectionAibnbReviews from "components/SectionAibnbReviews/SectionAibnbReviews";
import { TaxonomyType } from "data/types";
const DEMO_CATS: TaxonomyType[] = [
  {
    id: "1",
    href: "#",
    name: "New Yourk",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/BI_blue_background_vertical.png",
  },
  {
    id: "2",
    href: "#",
    name: "New Yourk",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/761ec63ba823cd88446152f20d7d8183.png",
  },
  {
    id: "3",
    href: "#",
    name: "New Yourk",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/7-NEWS-black.png",
  },
  {
    id: "4",
    href: "#",
    name: "New Yourk",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/Stuff.png",
  },
  {
    id: "5",
    href: "#",
    name: "New Yourk",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/Urban-List.png",
  },
  {
    id: "6",
    href: "#",
    name: "New Yourk",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/timeout-cs-logo-img-1.jpg",
  },
  {
    id: "7",
    href: "#",
    name: "New Yourk",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/the-weekend-Australian-magazine-1.png",
  },
  {
    id: "8",
    href: "#",
    name: "New Yourk",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/daily-telegraph-logo-vector-i1-1.png",
  },
  {
    id: "9",
    href: "#",
    name: "New Yourk",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/tfe-logo-768x123-2.png",
  }
];

const { REACT_APP_SITE_KEY } = process.env;

export interface PageContactProps {
  className?: string;
  autoFocus?: boolean;
}

const info = [
  {
    title: "🗺 ADDRESS",
    desc: "<p>3/ 23-25 Lara Way, Campbellfield, VIC 3061, Australia</p> <p>294 Palmyra Avenue, Shanes Park, NSW 2747, Australia</p>",
  },
  {
    title: "💌 EMAIL",
    desc: "ask@tinyaway.com",
  },

];


const infointernal = [
  {
    title: "🗺 ADDRESS",
    desc: "<p>1 Pemimpin Drive, #04-06 One Pemimpin, Singapore 576151 </p><p>1 Tuas South Ave 6, #03-06 The Westcom, Singapore 637021</p><p>​No 43 Jalan SME 3, Kawansan Perindustian SME, Bandar Indahpura, 81000, Kulai, Johor, Malaysia</p>",
  },
  {
    title: "💌 EMAIL",
    desc: "ask@bigtiny.com.sg ",
  },
  {
    title: "☎ PHONE",
    desc: "+65 6525 5282",
  },
];


const PageContact: FC<PageContactProps> = ({
  className = "",
  autoFocus = false
}) => {

  const [details, setDetails] = useState({
    name: "",
    email: "",
    contactnumber: "",
    subject: "Keep in Touch with Tiny Away",
    message: "",
    google_captcha: ""
  });
  const [showPopover, setShowPopover]                       = useState(autoFocus);
  const containerRef                                        = useRef<HTMLDivElement>(null);
  const inputRef                                            = useRef<HTMLInputElement>(null);
  const NameRef                                             = useRef<HTMLInputElement>(null);
  const EmailRef                                            = useRef<HTMLInputElement>(null);
  const ContactNumberRef                                    = useRef<HTMLInputElement>(null);
  const SubjectRef                                          = useRef<HTMLInputElement>(null);
  const MessagesRef                                         = useRef<HTMLInputElement>(null);
  const captchaRef                                          = useRef<ReCAPTCHA>(null);
  const [errorMsg, setErrorMsg]                             = useState('');
  const [successMsg, setSuccessMsg]                         = useState('');

  const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const isInvalid = validateInput();
    if (!isInvalid) {
      //const token = captchaRef.current?.getValue();
        captchaRef.current?.reset();
       // console.log(token);
        //console.log(details.google_captcha);
        
      axios.post(process.env.REACT_APP_BASE_URL + '/customer_enquiries', details)
        .then(function (response) {
          //console.log(response.data.response);
          if (response.data.status == 200) {
            setSuccessMsg(response.data.response);
            setDetails({
              name: "",
              email: "",
              contactnumber: "",
              subject: "",
              message: "",
              google_captcha: ""
            });
          } else {
            setErrorMsg(response.data.response);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      setSuccessMsg('');
    }
  };
  useEffect(() => {
    //console.log({ showPopover, 'EmailRef': EmailRef.current, 'NameRef': NameRef.current });
    if (showPopover && NameRef.current && !details.name) {
      NameRef.current.focus();
    } else if (showPopover && EmailRef.current && !details.email) {
      EmailRef.current.focus();
    } else if (showPopover && ContactNumberRef.current && !details.contactnumber) {
      ContactNumberRef.current.focus();
    } else if (showPopover && SubjectRef.current && !details.subject) {
      SubjectRef.current.focus();
    } else if (showPopover && MessagesRef.current && !details.message) {
      MessagesRef.current.focus();
    }
  }, [showPopover]);
  const validateInput = () => {
    const fields = [
      {
        name: 'name',
        value: details.name,
        message: 'Your Name should not be blank.'
      },
      {
        name: 'email',
        value: details.email,
        message: 'Your Email should not be blank.'
      },
      {
        name: 'contactnumber',
        value: details.contactnumber,
        message: 'Contact Number should not be blank.'
      },
      {
        name: 'subject',
        value: details.subject,
        message: 'Subject should not be blank.'
      },
      {
        name: 'message',
        value: details.message,
        message: 'Message should not be blank.'
      },
      {
        id: 'google_captcha',
        value: details.google_captcha,
        message: 'Captcha should not be blank.'
      }
    ]
    const isNotFilled = fields.some(field => {
      if (field.value.trim() === '') {
        setErrorMsg(field.message);
        setShowPopover(true);
        return true;
      }
      setShowPopover(false);
      setErrorMsg('');
      return false;
    });
    return isNotFilled;
  };
   function SetRecaptchaVal(value: any) {
     setDetails({ ...details, google_captcha:value});
   }
   const renderContactUS = () => {
    return (
      <div className="about-page-content">
        <div className="flex-2">
          <div>
            <img src="https://pictures.tinyaway.com/filters:format(jpg)/730x650/Listings/Images/2024/07/9/veucycgxnagvxz1wvk9i1721720571511.jpg" />
          </div>
          <div className="contaent-looking-hepl">
            <div>
            <h2>CONTACT US</h2>
            <p className="font-17">Looking for help? <br></br>Let us know—we’re here for you.</p>
            <div className="icon-flex">
              <div className="icon-set">
                <span><i className="las la-comments"></i></span>
                <p>The fastest and easiest way to get in touch with us about anything and everything.</p>
              </div>
              <div className="icon-set">
                <span><i className="las la-comment-dots"></i></span>
                <p>Our team will get back to you within 24 hours. </p><a href="">ask@tinyaway.com</a>
              </div>
              </div>

              
            </div>
          </div>
        </div>
        <div className="container relative space-y-10 mb-24 lg:space-y-10 lg:mb-32 mt-5">
          <h2 className="page-title mt-5 pt-4">INTERNATIONAL OFFICES</h2>
          <div className="grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-1 xl:grid-cols-1 grid-gap-15">
          <Tab.Group >
      <Tab.List >
        <Tab>
        <div>
              <div className="card-contact card-color-1" >
            <img src="https://cdn.tinyaway.com/common/2024/09/TA_UIUX_ContactCountries-351726303035583.png" />
            {/* <h4>AUSTRALIA OFFICES</h4> */}
            </div>
            </div>
        </Tab>
        <Tab><div>
           <div className="card-contact card-color-2">
              <img src="https://cdn.tinyaway.com/common/2024/09/TA_UIUX_ContactCountries-341726303225457.png" />
              {/* <h4>AUSTRALIA OFFICES</h4> */}
            </div>
          
          </div></Tab>
          <Tab><div>
             <div className="card-contact card-color-3">
              <img src="https://cdn.tinyaway.com/common/2024/09/TA_UIUX_ContactCountries-371726303272600.png" />
              {/* <h4>AUSTRALIA OFFICES</h4> */}
            </div> 
          </div></Tab>
      </Tab.List>
      <Tab.Panel>
      <div className="row-office-show-hide">
          <div className="office-details">
                <span><svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M122.9 159.9H37.1001C35.7001 159.9 34.6001 158.8 34.6001 157.4V42.3C34.6001 37.3 38.7001 33.2 43.7001 33.2H86.5001C87.9001 33.2 89.0001 34.3 89.0001 35.7C89.0001 37.1 87.9001 38.2 86.5001 38.2H43.7001C41.5001 38.2 39.6001 40 39.6001 42.3V154.9H120.4V90.8C120.4 89.4 121.5 88.3 122.9 88.3C124.3 88.3 125.4 89.4 125.4 90.8V157.4C125.4 158.8 124.3 159.9 122.9 159.9Z" fill="#59595B"/>
                        <path d="M68.9001 69.6H55.1001C52.6001 69.6 50.6001 67.6 50.6001 65.1V51.9C50.6001 49.4 52.6001 47.4 55.1001 47.4H68.9001C71.4001 47.4 73.4001 49.4 73.4001 51.9V65.1C73.4001 67.6 71.4001 69.6 68.9001 69.6ZM55.5001 64.6H68.3001V52.4H55.5001V64.6Z" fill="#59595B"/>
                        <path d="M101.3 69.6H90C87.5 69.6 85.5 67.6 85.5 65.1V47.6C85.5 46.2 86.6 45.1 88 45.1C89.4 45.1 90.5 46.2 90.5 47.6V64.6H101.3C102.7 64.6 103.8 65.7 103.8 67.1C103.8 68.5 102.7 69.6 101.3 69.6Z" fill="#59595B"/>
                        <path d="M68.9001 101.5H55.1001C52.6001 101.5 50.6001 99.5 50.6001 97V83.8C50.6001 81.3 52.6001 79.3 55.1001 79.3H68.9001C71.4001 79.3 73.4001 81.3 73.4001 83.8V97C73.4001 99.5 71.4001 101.5 68.9001 101.5ZM55.5001 96.5H68.3001V84.3H55.5001V96.5Z" fill="#59595B"/>
                        <path d="M103.8 101.5H90C87.5 101.5 85.5 99.5 85.5 97V83.8C85.5 81.3 87.5 79.3 90 79.3H103.8C106.3 79.3 108.3 81.3 108.3 83.8V97C108.3 99.5 106.3 101.5 103.8 101.5ZM90.5 96.5H103.3V84.3H90.5V96.5Z" fill="#59595B"/>
                        <path d="M101.6 159.9H58.4001C57.0001 159.9 55.9001 158.8 55.9001 157.4V124.6C55.9001 119.9 59.8001 116 64.5001 116H95.5001C100.2 116 104.1 119.9 104.1 124.6V157.4C104.1 158.8 103 159.9 101.6 159.9ZM60.9001 154.9H99.1001V124.6C99.1001 122.6 97.5001 121 95.5001 121H64.5001C62.5001 121 60.9001 122.6 60.9001 124.6V154.9Z" fill="#59595B"/>
                        <path d="M80 159.9C78.6 159.9 77.5 158.8 77.5 157.4V118.5C77.5 117.1 78.6 116 80 116C81.4 116 82.5 117.1 82.5 118.5V157.4C82.5 158.8 81.4 159.9 80 159.9Z" fill="#59595B"/>
                        <path d="M122.9 81.7C122.1 81.7 121.3 81.3 120.9 80.7C119.8 79.2 94.5 43.6 94.5 28.6C94.5 13.6 107.2 0.100006 122.9 0.100006C138.6 0.100006 151.3 12.8 151.3 28.5C151.3 44.2 126 79.1 124.9 80.6C124.4 81.3 123.7 81.6 122.9 81.6V81.7ZM122.9 5.10001C110 5.10001 99.5 15.6 99.5 28.5C99.5 41.4 114.9 63.1 122.9 74.7C130.9 63 146.3 38.6 146.3 28.5C146.3 18.4 135.8 5.10001 122.9 5.10001Z" fill="#59595B"/>
                        <path d="M122.9 44.2C114.5 44.2 107.7 37.4 107.7 29C107.7 20.6 114.5 13.8 122.9 13.8C131.3 13.8 138.1 20.6 138.1 29C138.1 37.4 131.3 44.2 122.9 44.2ZM122.9 18.8C117.3 18.8 112.7 23.4 112.7 29C112.7 34.6 117.3 39.2 122.9 39.2C128.5 39.2 133.1 34.6 133.1 29C133.1 23.4 128.5 18.8 122.9 18.8Z" fill="#59595B"/>
                        <path d="M37.1001 159.9H4.1001C2.7001 159.9 1.6001 158.8 1.6001 157.4C1.6001 156 2.7001 154.9 4.1001 154.9H37.1001C38.5001 154.9 39.6001 156 39.6001 157.4C39.6001 158.8 38.5001 159.9 37.1001 159.9Z" fill="#59595B"/>
                        <path d="M155.9 159.9H122.9C121.5 159.9 120.4 158.8 120.4 157.4C120.4 156 121.5 154.9 122.9 154.9H155.9C157.3 154.9 158.4 156 158.4 157.4C158.4 158.8 157.3 159.9 155.9 159.9Z" fill="#59595B"/>
                        </svg>
                </span>
                  <h1>AUSTRALIA OFFICES</h1>
                  <p>3/ 23-25 Lara Way, Campbellfield, VIC 3061, Australia</p>
                  <a href="">ask@tinyaway.com</a>
              </div>
              <div className="office-details">
                <span><svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M122.9 159.9H37.1001C35.7001 159.9 34.6001 158.8 34.6001 157.4V42.3C34.6001 37.3 38.7001 33.2 43.7001 33.2H86.5001C87.9001 33.2 89.0001 34.3 89.0001 35.7C89.0001 37.1 87.9001 38.2 86.5001 38.2H43.7001C41.5001 38.2 39.6001 40 39.6001 42.3V154.9H120.4V90.8C120.4 89.4 121.5 88.3 122.9 88.3C124.3 88.3 125.4 89.4 125.4 90.8V157.4C125.4 158.8 124.3 159.9 122.9 159.9Z" fill="#59595B"/>
                        <path d="M68.9001 69.6H55.1001C52.6001 69.6 50.6001 67.6 50.6001 65.1V51.9C50.6001 49.4 52.6001 47.4 55.1001 47.4H68.9001C71.4001 47.4 73.4001 49.4 73.4001 51.9V65.1C73.4001 67.6 71.4001 69.6 68.9001 69.6ZM55.5001 64.6H68.3001V52.4H55.5001V64.6Z" fill="#59595B"/>
                        <path d="M101.3 69.6H90C87.5 69.6 85.5 67.6 85.5 65.1V47.6C85.5 46.2 86.6 45.1 88 45.1C89.4 45.1 90.5 46.2 90.5 47.6V64.6H101.3C102.7 64.6 103.8 65.7 103.8 67.1C103.8 68.5 102.7 69.6 101.3 69.6Z" fill="#59595B"/>
                        <path d="M68.9001 101.5H55.1001C52.6001 101.5 50.6001 99.5 50.6001 97V83.8C50.6001 81.3 52.6001 79.3 55.1001 79.3H68.9001C71.4001 79.3 73.4001 81.3 73.4001 83.8V97C73.4001 99.5 71.4001 101.5 68.9001 101.5ZM55.5001 96.5H68.3001V84.3H55.5001V96.5Z" fill="#59595B"/>
                        <path d="M103.8 101.5H90C87.5 101.5 85.5 99.5 85.5 97V83.8C85.5 81.3 87.5 79.3 90 79.3H103.8C106.3 79.3 108.3 81.3 108.3 83.8V97C108.3 99.5 106.3 101.5 103.8 101.5ZM90.5 96.5H103.3V84.3H90.5V96.5Z" fill="#59595B"/>
                        <path d="M101.6 159.9H58.4001C57.0001 159.9 55.9001 158.8 55.9001 157.4V124.6C55.9001 119.9 59.8001 116 64.5001 116H95.5001C100.2 116 104.1 119.9 104.1 124.6V157.4C104.1 158.8 103 159.9 101.6 159.9ZM60.9001 154.9H99.1001V124.6C99.1001 122.6 97.5001 121 95.5001 121H64.5001C62.5001 121 60.9001 122.6 60.9001 124.6V154.9Z" fill="#59595B"/>
                        <path d="M80 159.9C78.6 159.9 77.5 158.8 77.5 157.4V118.5C77.5 117.1 78.6 116 80 116C81.4 116 82.5 117.1 82.5 118.5V157.4C82.5 158.8 81.4 159.9 80 159.9Z" fill="#59595B"/>
                        <path d="M122.9 81.7C122.1 81.7 121.3 81.3 120.9 80.7C119.8 79.2 94.5 43.6 94.5 28.6C94.5 13.6 107.2 0.100006 122.9 0.100006C138.6 0.100006 151.3 12.8 151.3 28.5C151.3 44.2 126 79.1 124.9 80.6C124.4 81.3 123.7 81.6 122.9 81.6V81.7ZM122.9 5.10001C110 5.10001 99.5 15.6 99.5 28.5C99.5 41.4 114.9 63.1 122.9 74.7C130.9 63 146.3 38.6 146.3 28.5C146.3 18.4 135.8 5.10001 122.9 5.10001Z" fill="#59595B"/>
                        <path d="M122.9 44.2C114.5 44.2 107.7 37.4 107.7 29C107.7 20.6 114.5 13.8 122.9 13.8C131.3 13.8 138.1 20.6 138.1 29C138.1 37.4 131.3 44.2 122.9 44.2ZM122.9 18.8C117.3 18.8 112.7 23.4 112.7 29C112.7 34.6 117.3 39.2 122.9 39.2C128.5 39.2 133.1 34.6 133.1 29C133.1 23.4 128.5 18.8 122.9 18.8Z" fill="#59595B"/>
                        <path d="M37.1001 159.9H4.1001C2.7001 159.9 1.6001 158.8 1.6001 157.4C1.6001 156 2.7001 154.9 4.1001 154.9H37.1001C38.5001 154.9 39.6001 156 39.6001 157.4C39.6001 158.8 38.5001 159.9 37.1001 159.9Z" fill="#59595B"/>
                        <path d="M155.9 159.9H122.9C121.5 159.9 120.4 158.8 120.4 157.4C120.4 156 121.5 154.9 122.9 154.9H155.9C157.3 154.9 158.4 156 158.4 157.4C158.4 158.8 157.3 159.9 155.9 159.9Z" fill="#59595B"/>
                        </svg>
                </span>
                  <h1>AUSTRALIA OFFICES</h1>
                  <p>294 Palmyra Avenue, Shanes Park, NSW 2747, Australia</p>
                  <a href="">ask@tinyaway.com</a>
              </div>
</div>
      </Tab.Panel>
      <Tab.Panel>
      <div className="row-office-show-hide">
          <div className="office-details">
                <span><svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M122.9 159.9H37.1001C35.7001 159.9 34.6001 158.8 34.6001 157.4V42.3C34.6001 37.3 38.7001 33.2 43.7001 33.2H86.5001C87.9001 33.2 89.0001 34.3 89.0001 35.7C89.0001 37.1 87.9001 38.2 86.5001 38.2H43.7001C41.5001 38.2 39.6001 40 39.6001 42.3V154.9H120.4V90.8C120.4 89.4 121.5 88.3 122.9 88.3C124.3 88.3 125.4 89.4 125.4 90.8V157.4C125.4 158.8 124.3 159.9 122.9 159.9Z" fill="#59595B"/>
                        <path d="M68.9001 69.6H55.1001C52.6001 69.6 50.6001 67.6 50.6001 65.1V51.9C50.6001 49.4 52.6001 47.4 55.1001 47.4H68.9001C71.4001 47.4 73.4001 49.4 73.4001 51.9V65.1C73.4001 67.6 71.4001 69.6 68.9001 69.6ZM55.5001 64.6H68.3001V52.4H55.5001V64.6Z" fill="#59595B"/>
                        <path d="M101.3 69.6H90C87.5 69.6 85.5 67.6 85.5 65.1V47.6C85.5 46.2 86.6 45.1 88 45.1C89.4 45.1 90.5 46.2 90.5 47.6V64.6H101.3C102.7 64.6 103.8 65.7 103.8 67.1C103.8 68.5 102.7 69.6 101.3 69.6Z" fill="#59595B"/>
                        <path d="M68.9001 101.5H55.1001C52.6001 101.5 50.6001 99.5 50.6001 97V83.8C50.6001 81.3 52.6001 79.3 55.1001 79.3H68.9001C71.4001 79.3 73.4001 81.3 73.4001 83.8V97C73.4001 99.5 71.4001 101.5 68.9001 101.5ZM55.5001 96.5H68.3001V84.3H55.5001V96.5Z" fill="#59595B"/>
                        <path d="M103.8 101.5H90C87.5 101.5 85.5 99.5 85.5 97V83.8C85.5 81.3 87.5 79.3 90 79.3H103.8C106.3 79.3 108.3 81.3 108.3 83.8V97C108.3 99.5 106.3 101.5 103.8 101.5ZM90.5 96.5H103.3V84.3H90.5V96.5Z" fill="#59595B"/>
                        <path d="M101.6 159.9H58.4001C57.0001 159.9 55.9001 158.8 55.9001 157.4V124.6C55.9001 119.9 59.8001 116 64.5001 116H95.5001C100.2 116 104.1 119.9 104.1 124.6V157.4C104.1 158.8 103 159.9 101.6 159.9ZM60.9001 154.9H99.1001V124.6C99.1001 122.6 97.5001 121 95.5001 121H64.5001C62.5001 121 60.9001 122.6 60.9001 124.6V154.9Z" fill="#59595B"/>
                        <path d="M80 159.9C78.6 159.9 77.5 158.8 77.5 157.4V118.5C77.5 117.1 78.6 116 80 116C81.4 116 82.5 117.1 82.5 118.5V157.4C82.5 158.8 81.4 159.9 80 159.9Z" fill="#59595B"/>
                        <path d="M122.9 81.7C122.1 81.7 121.3 81.3 120.9 80.7C119.8 79.2 94.5 43.6 94.5 28.6C94.5 13.6 107.2 0.100006 122.9 0.100006C138.6 0.100006 151.3 12.8 151.3 28.5C151.3 44.2 126 79.1 124.9 80.6C124.4 81.3 123.7 81.6 122.9 81.6V81.7ZM122.9 5.10001C110 5.10001 99.5 15.6 99.5 28.5C99.5 41.4 114.9 63.1 122.9 74.7C130.9 63 146.3 38.6 146.3 28.5C146.3 18.4 135.8 5.10001 122.9 5.10001Z" fill="#59595B"/>
                        <path d="M122.9 44.2C114.5 44.2 107.7 37.4 107.7 29C107.7 20.6 114.5 13.8 122.9 13.8C131.3 13.8 138.1 20.6 138.1 29C138.1 37.4 131.3 44.2 122.9 44.2ZM122.9 18.8C117.3 18.8 112.7 23.4 112.7 29C112.7 34.6 117.3 39.2 122.9 39.2C128.5 39.2 133.1 34.6 133.1 29C133.1 23.4 128.5 18.8 122.9 18.8Z" fill="#59595B"/>
                        <path d="M37.1001 159.9H4.1001C2.7001 159.9 1.6001 158.8 1.6001 157.4C1.6001 156 2.7001 154.9 4.1001 154.9H37.1001C38.5001 154.9 39.6001 156 39.6001 157.4C39.6001 158.8 38.5001 159.9 37.1001 159.9Z" fill="#59595B"/>
                        <path d="M155.9 159.9H122.9C121.5 159.9 120.4 158.8 120.4 157.4C120.4 156 121.5 154.9 122.9 154.9H155.9C157.3 154.9 158.4 156 158.4 157.4C158.4 158.8 157.3 159.9 155.9 159.9Z" fill="#59595B"/>
                        </svg>
                </span>
                  <h1>SINGAPORE OFFICES</h1>
                  <p>1 Pemimpin Drive, #04-06 One Pemimpin, Singapore 576151</p>
                  <a href="">ask@bigtiny.com.sg</a><br></br>
                  <a href="">+65 6525 5282</a>
              </div>
              <div className="office-details">
                <span><svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M122.9 159.9H37.1001C35.7001 159.9 34.6001 158.8 34.6001 157.4V42.3C34.6001 37.3 38.7001 33.2 43.7001 33.2H86.5001C87.9001 33.2 89.0001 34.3 89.0001 35.7C89.0001 37.1 87.9001 38.2 86.5001 38.2H43.7001C41.5001 38.2 39.6001 40 39.6001 42.3V154.9H120.4V90.8C120.4 89.4 121.5 88.3 122.9 88.3C124.3 88.3 125.4 89.4 125.4 90.8V157.4C125.4 158.8 124.3 159.9 122.9 159.9Z" fill="#59595B"/>
                        <path d="M68.9001 69.6H55.1001C52.6001 69.6 50.6001 67.6 50.6001 65.1V51.9C50.6001 49.4 52.6001 47.4 55.1001 47.4H68.9001C71.4001 47.4 73.4001 49.4 73.4001 51.9V65.1C73.4001 67.6 71.4001 69.6 68.9001 69.6ZM55.5001 64.6H68.3001V52.4H55.5001V64.6Z" fill="#59595B"/>
                        <path d="M101.3 69.6H90C87.5 69.6 85.5 67.6 85.5 65.1V47.6C85.5 46.2 86.6 45.1 88 45.1C89.4 45.1 90.5 46.2 90.5 47.6V64.6H101.3C102.7 64.6 103.8 65.7 103.8 67.1C103.8 68.5 102.7 69.6 101.3 69.6Z" fill="#59595B"/>
                        <path d="M68.9001 101.5H55.1001C52.6001 101.5 50.6001 99.5 50.6001 97V83.8C50.6001 81.3 52.6001 79.3 55.1001 79.3H68.9001C71.4001 79.3 73.4001 81.3 73.4001 83.8V97C73.4001 99.5 71.4001 101.5 68.9001 101.5ZM55.5001 96.5H68.3001V84.3H55.5001V96.5Z" fill="#59595B"/>
                        <path d="M103.8 101.5H90C87.5 101.5 85.5 99.5 85.5 97V83.8C85.5 81.3 87.5 79.3 90 79.3H103.8C106.3 79.3 108.3 81.3 108.3 83.8V97C108.3 99.5 106.3 101.5 103.8 101.5ZM90.5 96.5H103.3V84.3H90.5V96.5Z" fill="#59595B"/>
                        <path d="M101.6 159.9H58.4001C57.0001 159.9 55.9001 158.8 55.9001 157.4V124.6C55.9001 119.9 59.8001 116 64.5001 116H95.5001C100.2 116 104.1 119.9 104.1 124.6V157.4C104.1 158.8 103 159.9 101.6 159.9ZM60.9001 154.9H99.1001V124.6C99.1001 122.6 97.5001 121 95.5001 121H64.5001C62.5001 121 60.9001 122.6 60.9001 124.6V154.9Z" fill="#59595B"/>
                        <path d="M80 159.9C78.6 159.9 77.5 158.8 77.5 157.4V118.5C77.5 117.1 78.6 116 80 116C81.4 116 82.5 117.1 82.5 118.5V157.4C82.5 158.8 81.4 159.9 80 159.9Z" fill="#59595B"/>
                        <path d="M122.9 81.7C122.1 81.7 121.3 81.3 120.9 80.7C119.8 79.2 94.5 43.6 94.5 28.6C94.5 13.6 107.2 0.100006 122.9 0.100006C138.6 0.100006 151.3 12.8 151.3 28.5C151.3 44.2 126 79.1 124.9 80.6C124.4 81.3 123.7 81.6 122.9 81.6V81.7ZM122.9 5.10001C110 5.10001 99.5 15.6 99.5 28.5C99.5 41.4 114.9 63.1 122.9 74.7C130.9 63 146.3 38.6 146.3 28.5C146.3 18.4 135.8 5.10001 122.9 5.10001Z" fill="#59595B"/>
                        <path d="M122.9 44.2C114.5 44.2 107.7 37.4 107.7 29C107.7 20.6 114.5 13.8 122.9 13.8C131.3 13.8 138.1 20.6 138.1 29C138.1 37.4 131.3 44.2 122.9 44.2ZM122.9 18.8C117.3 18.8 112.7 23.4 112.7 29C112.7 34.6 117.3 39.2 122.9 39.2C128.5 39.2 133.1 34.6 133.1 29C133.1 23.4 128.5 18.8 122.9 18.8Z" fill="#59595B"/>
                        <path d="M37.1001 159.9H4.1001C2.7001 159.9 1.6001 158.8 1.6001 157.4C1.6001 156 2.7001 154.9 4.1001 154.9H37.1001C38.5001 154.9 39.6001 156 39.6001 157.4C39.6001 158.8 38.5001 159.9 37.1001 159.9Z" fill="#59595B"/>
                        <path d="M155.9 159.9H122.9C121.5 159.9 120.4 158.8 120.4 157.4C120.4 156 121.5 154.9 122.9 154.9H155.9C157.3 154.9 158.4 156 158.4 157.4C158.4 158.8 157.3 159.9 155.9 159.9Z" fill="#59595B"/>
                        </svg>
                </span>
                  <h1>SINGAPORE OFFICES</h1>
                  <p>1 Tuas South Ave 6, #03-06 The Westcom, Singapore 637021</p>
                  <a href="">ask@bigtiny.com.sg</a><br></br>
                  <a href="">+65 6525 5282</a>
              </div>
             
</div>
      </Tab.Panel>
      <Tab.Panel>
      <div className="row-office-show-hide">
          
              <div className="office-details">
                <span><svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M122.9 159.9H37.1001C35.7001 159.9 34.6001 158.8 34.6001 157.4V42.3C34.6001 37.3 38.7001 33.2 43.7001 33.2H86.5001C87.9001 33.2 89.0001 34.3 89.0001 35.7C89.0001 37.1 87.9001 38.2 86.5001 38.2H43.7001C41.5001 38.2 39.6001 40 39.6001 42.3V154.9H120.4V90.8C120.4 89.4 121.5 88.3 122.9 88.3C124.3 88.3 125.4 89.4 125.4 90.8V157.4C125.4 158.8 124.3 159.9 122.9 159.9Z" fill="#59595B"/>
                        <path d="M68.9001 69.6H55.1001C52.6001 69.6 50.6001 67.6 50.6001 65.1V51.9C50.6001 49.4 52.6001 47.4 55.1001 47.4H68.9001C71.4001 47.4 73.4001 49.4 73.4001 51.9V65.1C73.4001 67.6 71.4001 69.6 68.9001 69.6ZM55.5001 64.6H68.3001V52.4H55.5001V64.6Z" fill="#59595B"/>
                        <path d="M101.3 69.6H90C87.5 69.6 85.5 67.6 85.5 65.1V47.6C85.5 46.2 86.6 45.1 88 45.1C89.4 45.1 90.5 46.2 90.5 47.6V64.6H101.3C102.7 64.6 103.8 65.7 103.8 67.1C103.8 68.5 102.7 69.6 101.3 69.6Z" fill="#59595B"/>
                        <path d="M68.9001 101.5H55.1001C52.6001 101.5 50.6001 99.5 50.6001 97V83.8C50.6001 81.3 52.6001 79.3 55.1001 79.3H68.9001C71.4001 79.3 73.4001 81.3 73.4001 83.8V97C73.4001 99.5 71.4001 101.5 68.9001 101.5ZM55.5001 96.5H68.3001V84.3H55.5001V96.5Z" fill="#59595B"/>
                        <path d="M103.8 101.5H90C87.5 101.5 85.5 99.5 85.5 97V83.8C85.5 81.3 87.5 79.3 90 79.3H103.8C106.3 79.3 108.3 81.3 108.3 83.8V97C108.3 99.5 106.3 101.5 103.8 101.5ZM90.5 96.5H103.3V84.3H90.5V96.5Z" fill="#59595B"/>
                        <path d="M101.6 159.9H58.4001C57.0001 159.9 55.9001 158.8 55.9001 157.4V124.6C55.9001 119.9 59.8001 116 64.5001 116H95.5001C100.2 116 104.1 119.9 104.1 124.6V157.4C104.1 158.8 103 159.9 101.6 159.9ZM60.9001 154.9H99.1001V124.6C99.1001 122.6 97.5001 121 95.5001 121H64.5001C62.5001 121 60.9001 122.6 60.9001 124.6V154.9Z" fill="#59595B"/>
                        <path d="M80 159.9C78.6 159.9 77.5 158.8 77.5 157.4V118.5C77.5 117.1 78.6 116 80 116C81.4 116 82.5 117.1 82.5 118.5V157.4C82.5 158.8 81.4 159.9 80 159.9Z" fill="#59595B"/>
                        <path d="M122.9 81.7C122.1 81.7 121.3 81.3 120.9 80.7C119.8 79.2 94.5 43.6 94.5 28.6C94.5 13.6 107.2 0.100006 122.9 0.100006C138.6 0.100006 151.3 12.8 151.3 28.5C151.3 44.2 126 79.1 124.9 80.6C124.4 81.3 123.7 81.6 122.9 81.6V81.7ZM122.9 5.10001C110 5.10001 99.5 15.6 99.5 28.5C99.5 41.4 114.9 63.1 122.9 74.7C130.9 63 146.3 38.6 146.3 28.5C146.3 18.4 135.8 5.10001 122.9 5.10001Z" fill="#59595B"/>
                        <path d="M122.9 44.2C114.5 44.2 107.7 37.4 107.7 29C107.7 20.6 114.5 13.8 122.9 13.8C131.3 13.8 138.1 20.6 138.1 29C138.1 37.4 131.3 44.2 122.9 44.2ZM122.9 18.8C117.3 18.8 112.7 23.4 112.7 29C112.7 34.6 117.3 39.2 122.9 39.2C128.5 39.2 133.1 34.6 133.1 29C133.1 23.4 128.5 18.8 122.9 18.8Z" fill="#59595B"/>
                        <path d="M37.1001 159.9H4.1001C2.7001 159.9 1.6001 158.8 1.6001 157.4C1.6001 156 2.7001 154.9 4.1001 154.9H37.1001C38.5001 154.9 39.6001 156 39.6001 157.4C39.6001 158.8 38.5001 159.9 37.1001 159.9Z" fill="#59595B"/>
                        <path d="M155.9 159.9H122.9C121.5 159.9 120.4 158.8 120.4 157.4C120.4 156 121.5 154.9 122.9 154.9H155.9C157.3 154.9 158.4 156 158.4 157.4C158.4 158.8 157.3 159.9 155.9 159.9Z" fill="#59595B"/>
                        </svg>
                </span>
                  <h1>MALAYSIA OFFICES</h1>
                  <p>No 43 Jalan SME 3, Kawansan Perindustian SME, Bandar Indahpura, 81000, Kulai, Johor, Malaysia</p>
                  <a href="">ask@bigtiny.com.sg</a>
                  <a href="">+65 6525 5282</a>
              </div>
 
</div>
      </Tab.Panel>
    </Tab.Group>


          

          
          
          </div>



        </div>
        <div className="relative">
        <img className="top-5" src="https://pictures.tinyaway.com/filters:format(jpg)/1920x800/Listings/Images/2024/05/1/gwcisxhbjhxgshvrlxbf1714967317336.jpg" />
        <div className="hearing-from-content container">
          <div className="left-part-hedding mt-100">
            <h4>WE LOVE HEARING FROM YOU</h4>
            <p>If you have any questions or feedback, we're here to help.</p>
          </div>

          <div className="right-part-hedding mt-16">
            <div className="flex justify-center">
              <ButtonPrimary type="submit" className="btn-box-form"><i className="las la-arrow-right text-xl"></i></ButtonPrimary>
            </div>
            <div className="hearing-form-group">
              <h5>To Discover Nature and Stay in Comfort.</h5>
              <form className="grid grid-cols-1 gap-6" action="#" onSubmit={submitHandler} autoComplete="off" method="post">
                <label className="block">
                  <Input
                    type="text"
                    placeholder=""
                    name="name"
                    id="name"
                    onChange={event => setDetails({ ...details, name: event.target.value })}
                    ref={NameRef}
                    autoComplete="off"
                    autoFocus={showPopover}
                    value={details.name}
                  />
                </label>
                <label className="block">
                  <Input
                    type="email"
                    placeholder=""
                    name="email"
                    id="email"
                    onChange={event => setDetails({ ...details, email: event.target.value })}
                    ref={EmailRef}
                    autoComplete="off"
                    autoFocus={showPopover}
                    value={details.email}
                  />
                </label>
                <label className="block">
                  <Input
                    type="text"
                    placeholder=""
                    name="contactnumber"
                    id="contactnumber"
                    onChange={event => setDetails({ ...details, contactnumber: event.target.value })}
                    ref={ContactNumberRef}
                    autoComplete="off"
                    autoFocus={showPopover}
                    value={details.contactnumber}
                  />
                </label>
                <label className="block">
                  <Input
                    type="text"
                    placeholder=""
                    name="subject"
                    id="subject"
                    onChange={event => setDetails({ ...details, subject: event.target.value })}
                    ref={SubjectRef}
                    autoComplete="off"
                    autoFocus={showPopover}
                    value={details.subject}
                  />
                </label>
                <label className="block">
                  <Textarea placeholder=""
                    name="message"
                    id="message"
                    onChange={event => setDetails({ ...details, message: event.target.value })}
                    autoComplete="off"
                    autoFocus={showPopover}
                    value={details.message} rows={6}
                  />
                </label>
                <div>
                  {/* <ReCAPTCHA
                    // size="invisible"
                    sitekey={REACT_APP_SITE_KEY!}
                    ref={captchaRef}
                    id="google_captcha"
                    onChange={SetRecaptchaVal}
                  /> */}
                </div>
                <div className="mb-0 text-center">
                  <ButtonPrimary type="submit">Submit</ButtonPrimary>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="border-2 w-full clint-logo-about">
          <div className="container">
            <SectionAibnbReviews
              heading="featured In "
              subHeading=""
              className="hedding-center sm-hedding-text hedding-aboutus"
              categoryCardType="card6"
              categories={DEMO_CATS}
              itemPerRow={4}
            />
          </div>
        </div>
      </div>

       
      </div>

     
    )
  }

  return (
    <div className="full " >
      
      {renderContactUS()}
       <SectionSubscribe2 />
    </div>
  );
};

export default PageContact;
