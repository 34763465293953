import React, { FC } from "react";
import ButtonCircle from "shared/Button/ButtonCircle";
import rightImg from "images/2-750x500.jpg";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";
import Input from "shared/Input/Input";

export interface SectionSubscribe2Props {
  className?: string;
}

const SectionSubscribe2: FC<SectionSubscribe2Props> = ({ className = "" }) => {
  return (
    <div
      className={`flex-full nc-SectionSubscribe2 relative flex flex-col lg:flex-row lg:items-center ng-width-940 ${className}`}
      data-nc-id="SectionSubscribe2"
    > <div className="flex-grow ">
    <NcImage src={rightImg} />
  </div>
      <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mr-10 lg:w-2/5">
        <h2 className="text-5xl md:text-5xl font-semibold">Join Our Newsletter</h2>
        <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
        Receive exclusive email offers and promotions from Tiny Away. We know it is hard to book your preferred weekend dates due to overwhelming demand. Be on our mailing list and be informed on our newest tiny houses and what’s coming up soon!

        </span>
        <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
        More importantly, we always have exclusive offers and information for our Tiny Away family. Be the first to be informed.
         </span>
        
        <form className="mt-7 relative max-w-sm">
          <Input
            required
            aria-required
            placeholder="Enter your email"
            type="email"
          />
          <ButtonCircle
            type="submit"
            className="absolute transform top-1/2 -translate-y-1/2 right-1"
          >
            <i className="las la-arrow-right text-xl"></i>
          </ButtonCircle>
        </form>
      </div>
     
    </div>
  );
};

export default SectionSubscribe2;
