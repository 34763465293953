import Label from "components/Label/Label";
import React, { FC, Fragment,useState,useEffect,useRef } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official'
export interface PageGiftCardsRedeemProps {
  className?: string;
  DefaultUserID?: string;
}

const PageGiftCardsRedeem: FC<PageGiftCardsRedeemProps> = ({ 
  className = "",
  DefaultUserID = localStorage.getItem("user_id") ? localStorage.getItem("user_id") : "",
}) => {
  const options = {
    title: {
      text: 'Total Gift Card Value USD$500',
      align: 'left'
    },
    series: [{
      type: 'pie',
      data: [{
        y: 1,
        name: "Used Gift Value",
        color: "#000000"
    }, {
        y: 7,
        name: "Balance Gift Value",
        color: "#f15e75"
    }]
    }]
  }

  
const renderGiftCard = () => {
  return (
    <div className="right-user-details">
      <div className="flex justify-between">
    <h4>Gift Card</h4>
    <div className="search-wishlist">
      <span><i className="la la-search"></i></span>
      <input type="" placeholder="Search location" />
    </div>
    </div>
    <div className="list-tab-account">
    <a href="" className="active">All Purchased</a>
    <a href="">Completed</a> 
    <a href="">IN PROGRESS</a>
    </div>

    <div className="line-mid mt-5 mb-5"></div>
    <div className="flex">
      <div className="w-70">
        <div className="content w-1/2">
          <h4>HAPPY BIRTHDAY!</h4>
          <p>Wishing you a beautiful day with happiness and good health forever! Happy Birthday! May all your wishes and desires come true!</p>
          <p className="color-gry">Value: S$50 | Qty: 1 Purchased Date : Sun, 04 May 24</p>
          <h4>Sent To</h4>
          <p>g1@gmail.com</p>
          <p className="color-gry">Send by : your self</p>
          
        </div> 
        
      </div>
      <div className="w-30">
          <div className="right-box-add-on gap-3">
              
              <a href="" className="btn btn-box-theme">IN PROGRESS</a>
              <img src="https://pictures.tinyaway.com/filters:format(jpg)/190x160/Listings/Images/2024/09/952/ksegndmyjunkljeey8hp1725612069256.jpg" />
              <p className="confirmed color-light-blu">Download Gift Link</p>
             
          </div>
      </div>
    </div>

    <div className="line-mid mt-5 mb-5"></div>

    <div className="flex">
      <div className="w-70">
        <div className="content w-1/2">
          <h4>HAPPY BIRTHDAY!</h4>
          <p>Wishing you a beautiful day with happiness and good health forever! Happy Birthday! May all your wishes and desires come true!</p>
          <p className="color-gry">Value: S$50 | Qty: 1 Purchased Date : Sun, 04 May 24</p>
          <h4>Sent To</h4>
          <p>g1@gmail.com</p>
          <p className="color-gry">Send by : your self</p>
          
        </div> 
        
      </div>
      <div className="w-30">
          <div className="right-box-add-on gap-3">
              
              <a href="" className="btn btn-box-theme">IN PROGRESS</a>
              <img src="https://pictures.tinyaway.com/filters:format(jpg)/190x160/Listings/Images/2024/09/952/ksegndmyjunkljeey8hp1725612069256.jpg" />
              <p className="confirmed color-light-blu">Download Gift Link</p>
             
          </div>
      </div>
    </div>
  </div>
  );
};

  return (
    <div className="bgcolor-light pb-14 pt-10">
    <div className="container">

      <div className="row-profile  mt-5 mb-5">
       
      <CommonLayout/>
      {renderGiftCard ()}
       
      </div>
    </div>
  </div> 
  );
};
export default PageGiftCardsRedeem;
