import rightImg from "images/about-hero-right.png";
import videoplayback from "images/videoplayback.mp4";
import React, { FC,useState,useEffect,ComponentType } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import axios from 'axios'; 
import CommonLayout from "../AccountPage/CommonLayout";
export interface PageAboutProps {
  className?: string;
}
const PageAbout: FC<PageAboutProps> = ({
  className = "" 
}) => {

  const [description, setDescription]                         = useState("");
  const [metaTitle, setMetaTitle]                             = useState("");
  const [bannnerUrl, setBannnerUrl]                           = useState("");
  const [isVideo, setIsVideo]                                 = useState("");
  const [isImages, setIsImages]                               = useState("");
  const [isdataLoaded, setIsdataLoaded]                       = useState(false);


  useEffect(() => {
      axios.get(process.env.REACT_APP_BASE_URL+'getPages?page=about_us')
  .then(res => {
    console.log(res.data);
    setDescription(res.data.page_details.content_html);
    setMetaTitle(res.data.page_details.meta_title);
    setBannnerUrl(res.data.page_details.banner_url);
    setIsVideo(res.data.page_details.is_video);
    setIsImages(res.data.page_details.is_images);
    setIsdataLoaded(true);
  })
}, [isdataLoaded]);
const renderWishlists = () => {
  return (
    <div className="right-user-details">
      <div className="flex justify-between">
    <h4>Wishlists</h4>
    <div className="search-wishlist">
      <span><i className="la la-search"></i></span>
      <input type="" placeholder="Search location" />
    </div>
    </div>
    <div className="line-mid mt-5 mb-5"></div>
  
  </div>
  );
};
  return (
    <div className="bgcolor-light pb-14 pt-10">
    <div className="container">

      <div className="row-profile  mt-5 mb-5">
       
      <CommonLayout/>
      {renderWishlists ()}
       
      </div>
    </div>
  </div>
  );
};

export default PageAbout;
