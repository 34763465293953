import rightImg from "images/about-hero-right.png";
import videoplayback from "images/videoplayback.mp4";
import React, { FC, Fragment, useState, useEffect, useRef } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionAboutVideo from "./SectionAboutVideo";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionAibnbReviews from "components/SectionAibnbReviews/SectionAibnbReviews";
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
import { TaxonomyType } from "data/types";

const DEMO_CATS: TaxonomyType[] = [
  {
    id: "1",
    href: "#",
    name: "New Yourk",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/BI_blue_background_vertical.png",
  },
  {
    id: "2",
    href: "#",
    name: "New Yourk",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/761ec63ba823cd88446152f20d7d8183.png",
  },
  {
    id: "3",
    href: "#",
    name: "New Yourk",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/7-NEWS-black.png",
  },
  {
    id: "4",
    href: "#",
    name: "New Yourk",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/Stuff.png",
  },
  {
    id: "5",
    href: "#",
    name: "New Yourk",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/Urban-List.png",
  },
  {
    id: "6",
    href: "#",
    name: "New Yourk",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/timeout-cs-logo-img-1.jpg",
  },
  {
    id: "7",
    href: "#",
    name: "New Yourk",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/the-weekend-Australian-magazine-1.png",
  },
  {
    id: "8",
    href: "#",
    name: "New Yourk",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/daily-telegraph-logo-vector-i1-1.png",
  },
  {
    id: "9",
    href: "#",
    name: "New Yourk",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/tfe-logo-768x123-2.png",
  }
];

const { REACT_APP_SITE_KEY } = process.env;
export interface PageFaqCancellationPolicyProps {
  className?: string;
  autoFocus?: boolean;
}
const PageFaqCancellationPolicy: FC<PageFaqCancellationPolicyProps> = ({
  className = "",
  autoFocus = false
}) => {

  const [details, setDetails] = useState({
    name: "",
    email: "",
    contactnumber: "",
    subject: "Keep in Touch with Tiny Away",
    message: "",
    google_captcha: ""
  });
  const [showPopover, setShowPopover] = useState(autoFocus);
  const [description, setDescription] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [bannnerUrl, setBannnerUrl] = useState("");
  const [isVideo, setIsVideo] = useState("");
  const [isImages, setIsImages] = useState("");
  const [isdataLoaded, setIsdataLoaded] = useState(false);
  const NameRef = useRef<HTMLInputElement>(null);
  const EmailRef = useRef<HTMLInputElement>(null);
  const ContactNumberRef = useRef<HTMLInputElement>(null);
  const SubjectRef = useRef<HTMLInputElement>(null);
  const MessagesRef = useRef<HTMLInputElement>(null);
  const captchaRef = useRef<ReCAPTCHA>(null);
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');

  const renderHearingFromYou = () => {
    return (
      <div className="relative">
        <img className="top-5" src="https://pictures.tinyaway.com/filters:format(jpg)/1920x800/Listings/Images/2024/05/1/gwcisxhbjhxgshvrlxbf1714967317336.jpg" />
        <div className="hearing-from-content container">
          <div className="left-part-hedding mt-100">
            <h4>WE LOVE HEARING FROM YOU</h4>
            <p>If you have any questions or feedback, we're here to help.</p>
          </div>

          <div className="right-part-hedding mt-16">
            <div className="flex justify-center">
              <ButtonPrimary type="submit" className="btn-box-form"><i className="las la-arrow-right text-xl"></i></ButtonPrimary>
            </div>
            <div className="hearing-form-group">
              <h5>To Discover Nature and Stay in Comfort.</h5>
              <form className="grid grid-cols-1 gap-6" action="#" onSubmit={submitHandler} autoComplete="off" method="post">
                <label className="block">
                  <Input
                    type="text"
                    placeholder=""
                    name="name"
                    id="name"
                    onChange={event => setDetails({ ...details, name: event.target.value })}
                    ref={NameRef}
                    autoComplete="off"
                    autoFocus={showPopover}
                    value={details.name}
                  />
                </label>
                <label className="block">
                  <Input
                    type="email"
                    placeholder=""
                    name="email"
                    id="email"
                    onChange={event => setDetails({ ...details, email: event.target.value })}
                    ref={EmailRef}
                    autoComplete="off"
                    autoFocus={showPopover}
                    value={details.email}
                  />
                </label>
                <label className="block">
                  <Input
                    type="text"
                    placeholder=""
                    name="contactnumber"
                    id="contactnumber"
                    onChange={event => setDetails({ ...details, contactnumber: event.target.value })}
                    ref={ContactNumberRef}
                    autoComplete="off"
                    autoFocus={showPopover}
                    value={details.contactnumber}
                  />
                </label>
                <label className="block">
                  <Input
                    type="text"
                    placeholder=""
                    name="subject"
                    id="subject"
                    onChange={event => setDetails({ ...details, subject: event.target.value })}
                    ref={SubjectRef}
                    autoComplete="off"
                    autoFocus={showPopover}
                    value={details.subject}
                  />
                </label>
                <label className="block">
                  <Textarea placeholder=""
                    name="message"
                    id="message"
                    onChange={event => setDetails({ ...details, message: event.target.value })}
                    autoComplete="off"
                    autoFocus={showPopover}
                    value={details.message} rows={6}
                  />
                </label>
                <div>
                  {/* <ReCAPTCHA
                    // size="invisible"
                    sitekey={REACT_APP_SITE_KEY!}
                    ref={captchaRef}
                    id="google_captcha"
                    onChange={SetRecaptchaVal}
                  /> */}
                </div>
                <div className="mb-0 text-center">
                  <ButtonPrimary type="submit">Submit</ButtonPrimary>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="border-2 w-full clint-logo-about">
          <div className="container">
            <SectionAibnbReviews
              heading="featured In "
              subHeading=""
              className="hedding-center sm-hedding-text hedding-aboutus"
              categoryCardType="card6"
              categories={DEMO_CATS}
              itemPerRow={4}
            />
          </div>
        </div>
      </div>
    )
  }
  useEffect(() => {
    axios.get(process.env.REACT_APP_BASE_URL + 'getPages?page=about_us')
      .then(res => {
        console.log(res.data);
        setDescription(res.data.page_details.content_html);
        setMetaTitle(res.data.page_details.meta_title);
        setBannnerUrl(res.data.page_details.banner_url);
        setIsVideo(res.data.page_details.is_video);
        setIsImages(res.data.page_details.is_images);
        setIsdataLoaded(true);
      })
  }, [isdataLoaded]);
  const renderskeletonLoader = () => {
    return (
      <div className="custom-loader"><div className="h-screen skeleton"></div></div>
    );
  };


  const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const isInvalid = validateInput();
    if (!isInvalid) {
      //const token = captchaRef.current?.getValue();
      captchaRef.current?.reset();
      // console.log(token);
      //console.log(details.google_captcha);

      axios.post(process.env.REACT_APP_BASE_URL + '/customer_enquiries', details)
        .then(function (response) {
          //console.log(response.data.response);
          if (response.data.status == 200) {
            setSuccessMsg(response.data.response);
            setDetails({
              name: "",
              email: "",
              contactnumber: "",
              subject: "",
              message: "",
              google_captcha: ""
            });
          } else {
            setErrorMsg(response.data.response);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      setSuccessMsg('');
    }
  };
  useEffect(() => {
    //console.log({ showPopover, 'EmailRef': EmailRef.current, 'NameRef': NameRef.current });
    if (showPopover && NameRef.current && !details.name) {
      NameRef.current.focus();
    } else if (showPopover && EmailRef.current && !details.email) {
      EmailRef.current.focus();
    } else if (showPopover && ContactNumberRef.current && !details.contactnumber) {
      ContactNumberRef.current.focus();
    } else if (showPopover && SubjectRef.current && !details.subject) {
      SubjectRef.current.focus();
    } else if (showPopover && MessagesRef.current && !details.message) {
      MessagesRef.current.focus();
    }
  }, [showPopover]);
  const validateInput = () => {
    const fields = [
      {
        name: 'name',
        value: details.name,
        message: 'Your Name should not be blank.'
      },
      {
        name: 'email',
        value: details.email,
        message: 'Your Email should not be blank.'
      },
      {
        name: 'contactnumber',
        value: details.contactnumber,
        message: 'Contact Number should not be blank.'
      },
      {
        name: 'subject',
        value: details.subject,
        message: 'Subject should not be blank.'
      },
      {
        name: 'message',
        value: details.message,
        message: 'Message should not be blank.'
      },
      {
        id: 'google_captcha',
        value: details.google_captcha,
        message: 'Captcha should not be blank.'
      }
    ]
    const isNotFilled = fields.some(field => {
      if (field.value.trim() === '') {
        setErrorMsg(field.message);
        setShowPopover(true);
        return true;
      }
      setShowPopover(false);
      setErrorMsg('');
      return false;
    });
    return isNotFilled;
  };
  function SetRecaptchaVal(value: any) {
    setDetails({ ...details, google_captcha: value });
  }

  return (
    <div className={`nc-PageFaqCancellationPolicy overflow-hidden relative ${className}`} data-nc-id="PageFaqCancellationPolicy">
      <Helmet title={metaTitle}></Helmet>
      <BgGlassmorphism />
      {isdataLoaded ?
        <div className=" w-full">
          <div className="tw-banner ">
            <video width="100%" loop autoPlay={true} height="100%" controls className="group  bg-neutral-800 rounded-0 overflow-hidden border-0 border-white dark:border-neutral-900 sm:rounded-[0px] sm:border-[10px]" >
              <source src={bannnerUrl} type="video/mp4" /> </video>

            <div className="main-about-banner">
              <div>
                <h1><span>OUR TINY STORY</span></h1>
                <p>It all started in 2017 when a young man named Adrian was on vacation at the picturesque Great Ocean Road. He loved the beautiful coastal scenery and felt that this was exactly what city dwellers would need in order to re-energise ourselves.</p>
              </div>
            </div>

          </div>

        </div>
        :
        <div className="container py-16 pt-0 lg:py-18 space-y-16 lg:space-y-28">
          {renderskeletonLoader()}
        </div>

      }

      <div className="container">
        <div className="about-box">
          <h5> “Those of us who live in the city lead a hectic, digital lifestyle. Most of the time, all we want is a getaway”</h5>
          <div className="flex gap-8 mt-10">
            <div className="w-1/2">The young man partnered with two friends, Dave and Jeff to build tiny houses and place them on land with beautiful scenery. Thus Tiny Away was born. It was born out of a desire to offer city dwellers a place to escape from the city and recharge in nature.</div>
            <div className="w-1/2">The tiny house movement started off in America and became an international trend. It is an architectural and social philosophy that advocates living simply in small, affordable and eco-friendly homes. In order to live in a smaller space, one would have to downsize and simplify their lives by reducing the amount of material possessions they have. As such, tiny houses help to reduce resource consumption and are a cost-effective and sustainable way of living.</div>
          </div>
          <img className="rounded-2xl mt-24 mb-24" src="https://pictures.tinyaway.com/filters:format(jpg)/1920x720/Listings/Images/2024/07/9/veucycgxnagvxz1wvk9i1721720571511.jpg"></img>
        </div>
      </div>

      <div className="container">
        <div className="about-box mb-24">
          <div className="flex gap-8 mt-10">
            <div className=" ">
              <img className="rounded-2xl" src="https://pictures.tinyaway.com/filters:format(jpg)/600x350/Listings/Images/2024/07/9/veucycgxnagvxz1wvk9i1721720571511.jpg"></img>
            </div>
            <div className="column flex gap-5 pt-10 w-1/2 flex-direction-column">
              <p className="mb-100">
                Riding on the international trend in tiny houses, we have taken the tiny house movement one step further by partnering with land hosts and placing our beautifully designed and handcrafted homes on wheels in spectacular rural settings, surrounded by Nature.
              </p>
              <p>We believe that all good things should be shared. To share this with everyone, we have integrated the concept of tiny houses with eco-tourism. Our tiny houses are designed to be eco-friendly and are built with sustainable materials. By putting our tiny houses on booking platforms like Airbnb and Booking.com, we have made it possible for anyone to book a stay in a tiny house. This gives people, especially those stressed out city folks, a chance to experience the tiny house lifestyle in Nature</p>
              <div className="flex  gap-4">
                <ButtonPrimary type="submit">BECOME A HOST</ButtonPrimary>
                <ButtonPrimary type="submit">CONTACT US</ButtonPrimary>
              </div>
            </div>
          </div>

        </div>
      </div>

      {renderHearingFromYou()}


      <SectionSubscribe2 />
    </div>

  );
};

export default PageFaqCancellationPolicy;
