import React, { FC } from "react";

export interface SaleOffBadgeProps {
  className?: string;
  desc?: string;
}

const SaleOffBadge: FC<SaleOffBadgeProps> = ({
  className = "",
  desc = "-10% today",
}) => {
  return (
    <div
      className={`Source-Sans-3 bg-primary-color nc-SaleOffBadge flex items-center justify-center text-xs py-0.5 px-3 bg-red-700 text-red-50 rounded-full ${className}`}
      data-nc-id="SaleOffBadge"
    >
      <svg width="20" height="20" className="mr-1" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.27 0L7.81 6.97L0 8.1L5.66 13.58L4.34 21.27L11.29 17.64L18.24 21.27L16.91 13.58L22.57 8.1L14.75 6.97L11.29 0H11.27Z" fill="black" />
      </svg>

      <span> {desc}</span>
    </div>
  );
};

export default SaleOffBadge;
