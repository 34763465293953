import React, { FC, Fragment, ReactNode, useEffect, useState, useRef } from "react";
import { useParams } from 'react-router-dom';
import { Dialog, Transition } from "@headlessui/react";
import { ArrowRightIcon } from "@heroicons/react/outline";
import LocationMarker from "components/AnyReactComponent/LocationMarker";
import CommentListing from "components/CommentListing/CommentListing";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import GuestsInput from "components/HeroSearchForm/GuestsInput";
import StayDatesRangeInput from "components/HeroSearchForm/StayDatesRangeInput";
import { DateRage } from "components/HeroSearchForm/StaySearchForm";
import StartRating from "components/StartRating/StartRating";
import GoogleMapReact from "google-map-react";
import useWindowSize from "hooks/useWindowResize";
import moment from "moment";
import { DayPickerRangeController, FocusedInputShape } from "react-dates";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonCircle from "shared/Button/ButtonCircle";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import NcImage from "shared/NcImage/NcImage";
import LikeSaveBtns from "./LikeSaveBtns";
import ModalPhotos from "./ModalPhotos";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionGridSimilarListing from "./SectionGridSimilarListing";
import SectionGridSimilarStories from "./SectionGridSimilarStories";
import { Helmet } from "react-helmet";
import axios from 'axios';
import { convertCompilerOptionsFromJson } from "typescript";
import { Route, Redirect, useHistory, Link } from 'react-router-dom';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import facebookSvg from "images/Facebook.svg";
import googleSvg from "images/Google.svg";
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, PinterestShareButton, LinkedinShareButton, EmailShareButton, FacebookMessengerShareButton } from "react-share";
import { FacebookIcon, TwitterIcon, WhatsappIcon, PinterestIcon, LinkedinIcon, EmailIcon } from "react-share";
import ReCAPTCHA from "react-google-recaptcha";
import useCopy from 'use-copy';
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import Collapsible from 'react-collapsible';
import { render } from "@testing-library/react";


const { REACT_APP_SITE_KEY } = process.env;
export interface ListingStayDetailPageProps {
  className?: string;
  isPreviewMode?: boolean;
  autoFocus?: boolean;
  DefaultUserID?: number;
}
const ListingStayDetailPage: FC<ListingStayDetailPageProps> = ({
  className = "",
  isPreviewMode,
  autoFocus = false,
  DefaultUserID = localStorage.getItem("user_id") ? localStorage.getItem("user_id") : 0
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);
  const [selectedDate, setSelectedDate] = useState<DateRage>({
    /*startDate: moment(),
    endDate: moment().add(1, "days"),*/
    startDate: null,
    endDate: null,
  });
  const params = useParams();
  const slug = Object.values(params);
  const history = useHistory();
  const [isdataLoaded, setIsdataLoaded] = useState(false);
  const [datall, setAatall] = useState([]);
  const [imgUrl1, setimgUrl] = useState([]);
  const [authorname, setAuthorname] = useState([]);
  const [hostinfo, setHostinfo] = useState("");
  const [totalhosted, setTotalhosted] = useState(0);
  const [title, setTitle] = useState("");
  const [beds, setBeds] = useState([]);
  const [bedrooms, setBedrooms] = useState([]);
  const [bath, setBath] = useState([]);
  const [maxguest, setMaxguest] = useState(0);
  const [maxAdults, setMaxAdults] = useState(0);
  const [maxChilds, setMaxChilds] = useState(0);


  const [PHOTOS, setPHOTOS] = useState([]);
  const [listingaddress, listingAddress] = useState([]);
  const [price, setPrice] = useState([]);
  const [descriptionsummary, setDescriptionsummary] = useState([]);
  const [descriptionspace, setDescriptionspace] = useState([]);
  const [descriptionaccess, setDescriptionaccess] = useState([]);
  const [descriptionnotes, setDescriptionnotes] = useState([]);
  const [descriptionneighborhood, setDescriptionneighborhood] = useState([]);
  const [reviewStart, setReviewStart] = useState(0);
  const [reviewCount, setReviewCount] = useState(0);
  const [mapzoom, setMapzoom] = useState(6);
  const [currency_code, Setcurrency_code] = useState([]);
  const [allReviews, SetAllreviews] = useState([]);
  const [metatitle, SetMetatitle] = useState("");
  const [metadata, SetMetaData] = useState([]);
  const [mapcoordinates, setMapcoordinates] = useState({
    "lat": -37.3218879,
    "lng": 145.3109719
  });
  const [maplat, setMaplat] = useState(-37.3218879);
  const [maplng, setMaplng] = useState(145.3109719);
  const [maplatSimilar, setMaplatSimilar] = useState("");
  const [maplngSimilar, setMaplngSimilar] = useState("");
  const [propertyArea, SetpropertyArea] = useState([]);
  const [listingType, SetlistingType] = useState([]);
  const [defaultCheckInTime, SetDefaultCheckInTime] = useState([]);
  const [defaultCheckOutTime, SetDefaultCheckOutTime] = useState([]);
  const [NightlyPrice, SetNightlyPrice] = useState([]);
  const [WeekendsPrice, SetWeekendsPrice] = useState([]);
  const [WeeklyPrice, SetWeeklyPrice] = useState([]);
  const [MonthlyPrice, SetMonthlyPrice] = useState([]);
  const [CleaningFee, SetCleaningFee] = useState([]);
  const [AdditionGuestPrice, SetAdditionGuestPrice] = useState([]);
  const [AdditionGuestAllowed, SetAdditionGuestAllowed] = useState([]);
  const [weekdaysname, SetWeekdaysname] = useState([]);
  const [amenities, SetAmenities] = useState<any[]>([]);
  const [totalAmenities, SettotalAmenities] = useState<any[]>([]);
  const [totalReviews, SettotalReviews] = useState<any[]>([]);
  const [allowsChildren, SetallowsChildren] = useState<any[]>([]);
  const [allowsInfants, SetallowsInfants] = useState<any[]>([]);
  const [allowsSmoking, SetallowsSmoking] = useState<any[]>([]);
  const [allowsEvents, SetallowsEvents] = useState<any[]>([]);
  const [allowsPets, SetallowsPets] = useState<any[]>([]);
  const [addition_information, Setaddition_information] = useState<any[]>([]);
  const [mobile_reception, Setmobile_reception] = useState<any[]>([]);
  const [outdoor_fire_pit, Setoutdoor_fire_pit] = useState<any[]>([]);
  const [firewood_provided_free, Setfirewood_provided_free] = useState<any[]>([]);
  const [firewood_at_extra_cost, Setfirewood_at_extra_cost] = useState("");
  const [acctivities_and_potential_wildlife, Setacctivities_and_potential_wildlife] = useState("");
  const [how_isolated_is_the_tiny_house, Sethow_isolated_is_the_tiny_house] = useState("");
  const [cancellation_policy, Setcancellation_policy] = useState<any[]>([]);
  const [Pet_frindly, SetPet_frindly] = useState<any[]>([]);
  const [guestyListingId, setguestyListingId] = useState("");
  const [calendarMessage, setCalendarMessage] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [priceBreakDown, setPriceBreakDown] = useState("");
  const [adults, setAdults] = useState(2);
  const [children, setChildren] = useState(0);
  const [infants, setInfants] = useState(0);
  const [availableDates, setavailableDates] = useState("");
  const [checkoutOnlyDates, CheckoutOnlyDates] = useState("");
  const [selectedDateForyear, setSelectedDateForyear] = useState<DateRage>({
    startDate: moment(),
    endDate: moment().add(12, "month"),
  });
  const [details, setDetails] = useState({ email: "", password: "" });
  const [showPopover, setShowPopover] = useState(autoFocus);
  const [error, setError] = useState('');
  const [login, setLogin] = useState(false);
  const firstNameRef = useRef<HTMLInputElement>(null);
  const lastNameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const [errorMsg, setErrorMsg] = useState('');
  const [success, setsuccess] = useState('');
  const [user_id, setUserId] = useState(DefaultUserID);
  const [pageLink, setPageLink] = useState("");
  const [cityName, setCityName] = useState("");
  const [stateName, setStateName] = useState("");
  const [countryName, setCountryName] = useState("");
  const [focusedInputSectionCheckDate, setFocusedInputSectionCheckDate] = useState<FocusedInputShape>("startDate");
  const [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);
  const [isOpenModalLoginSignUp, setIsOpenModalLoginSignUp] = useState(false);
  const [isModalHostContactUs, setIsModalHostContactUs] = useState(false);
  const [isOpenModalReviews, setisOpenModalReviews] = useState(false);
  const [isOpenModalMobileBookingForm, setIsOpenModalMobileBookingForm] = useState("hidden");
  const windowSize = useWindowSize();
  const [detailsHostContact, setDetailsHostContact] = useState({ host_contact_name: "", host_contact_email: "", host_contact_phone: "", host_contact_message: "", google_captcha: "" });
  const captchaRef = useRef<ReCAPTCHA>(null);
  const [checkInDetails, setCheckInDetails] = useState({ checkInCheckOutDate: { startDate: selectedDate.startDate, endDate: selectedDate.endDate }, listing_id: guestyListingId, adults: adults, children: children, infants: infants });
  const [isOpenModalLikeShare, setIsOpenModalLikeShare] = useState(false);
  const [copied, copy, setCopied] = useCopy(pageLink);
  const [featuredImage, setFeaturedImage] = useState("");

  const [errorcontact, setErrorContact] = useState('');
  const [successcontact, setSuccessContact] = useState('');
  const clientId = process.env.REACT_APP_GMAIL_CLIENT_ID;




  useEffect(() => {
    setUserId(localStorage.getItem("user_id"));
  }, [DefaultUserID]);//

  useEffect(() => {
    setIsdataLoaded(false);
  }, [history.location.pathname]);//
  const loginSocials = [
    // {
    //   name: "Continue with Twitter",
    //   href: "#",
    //   icon: twitterSvg,
    // },
    {
      name: "Continue with Google",
      href: "#",
      icon: googleSvg,
    },
  ];
  const isOutsideRange = (day: any) => {
    return day.isBefore(moment().subtract(1, "days"));
  }
  useEffect(() => {
    if (selectedDate?.startDate != null && selectedDate?.endDate != null) {
      axios.get(process.env.REACT_APP_BASE_URL + 'check_booking_availability_on_date?listing_id=' + guestyListingId + '&checkin=' + selectedDate.startDate.format("YYYY-MM-DD") + '&checkout=' + selectedDate.endDate.format("YYYY-MM-DD") + "&adults=" + adults + "&children=" + children + "&infants=" + infants)
        .then(res => {
          if (res.data.success == true) {
            setPriceBreakDown(res.data.response);
            setPrice(res.data.price_per_night);
            setCalendarMessage("");
            setSuccessMsg(res.data.message);
          } else {
            setCalendarMessage(res.data.message);
            setSuccessMsg("");
          }
        })
    }
  }, [selectedDate, adults, children]);
  useEffect(() => {
    axios.get(process.env.REACT_APP_BASE_URL + 'GetListingDetails?id=' + slug)
      .then(res => {
        setAatall(res.data);
        setPageLink(res.data.href);
        setAuthorname(res.data.author_name);
        setHostinfo(res.data.hostinfo);
        setTotalhosted(res.data.total_hosted);
        SetAmenities(res.data.amenities);
        SetNightlyPrice(res.data.NightlyPrice);
        SetWeekendsPrice(res.data.WeekendsPrice);
        SetWeeklyPrice(res.data.WeeklyPrice);
        SetMonthlyPrice(res.data.MonthlyPrice);
        SetCleaningFee(res.data.CleaningFee);
        SetAdditionGuestPrice(res.data.AdditionGuestPrice);
        SetAdditionGuestAllowed(res.data.AdditionGuestAllowed);
        SettotalAmenities(res.data.totalAmenities);
        SettotalReviews(res.data.totalReviews);
        setimgUrl(res.data.author_pic);
        setTitle(res.data.title);
        setBeds(res.data.beds);
        setBedrooms(res.data.bedrooms);
        setBath(res.data.bathrooms);
        setMaxguest(res.data.maxGuests);
        setPHOTOS(res.data.galleryImgs);
        listingAddress(res.data.address);
        setPrice(res.data.price);
        setDescriptionsummary(res.data.publicDescription_summary);
        setDescriptionspace(res.data.publicDescription_space);
        setDescriptionaccess(res.data.publicDescription_access);
        setDescriptionnotes(res.data.publicDescription_notes);
        setDescriptionneighborhood(res.data.publicDescription_neighborhood);
        setReviewStart(res.data.reviewStart);
        setReviewCount(res.data.reviewCount);
        setMapcoordinates(res.data.map);
        Setcurrency_code(res.data.currency_code);
        setMaplat(res.data.map.lat);
        setMaplng(res.data.map.lng);
        setMapzoom(14);
        SetAllreviews(res.data.all_reviews);
        SetMetatitle(res.data.meta_title);
        SetMetaData(res.data.meta_data);
        SetpropertyArea(res.data.property_area);
        SetlistingType(res.data.listing_type);
        SetDefaultCheckInTime(res.data.defaultCheckInTime);
        SetDefaultCheckOutTime(res.data.defaultCheckOutTime);
        SetWeekdaysname(res.data.weekdaysname);
        SetallowsChildren(res.data.guestControls_allowsChildren);
        SetallowsInfants(res.data.guestControls_allowsInfants);
        SetallowsSmoking(res.data.guestControls_allowsSmoking);
        SetallowsEvents(res.data.guestControls_allowsEvents);
        SetallowsPets(res.data.guestControls_allowsPets);
        Setaddition_information(res.data.addition_information);
        Setmobile_reception(res.data.mobile_reception);
        Setoutdoor_fire_pit(res.data.outdoor_fire_pit);
        Setfirewood_provided_free(res.data.firewood_provided_free);
        Setfirewood_at_extra_cost(res.data.firewood_at_extra_cost);
        Setacctivities_and_potential_wildlife(res.data.acctivities_and_potential_wildlife);
        Sethow_isolated_is_the_tiny_house(res.data.how_isolated_is_the_tiny_house);
        Setcancellation_policy(res.data.cancellation_policy);
        SetPet_frindly(res.data.Pet_frindly);
        setMaplatSimilar(res.data.map.lat);
        setMaplngSimilar(res.data.map.lng);
        setguestyListingId(res.data.id);
        // setCheckInDetails({...checkInDetails,listing_id:res.data.id});
        setavailableDates(res.data.availability);
        setCityName(res.data.city);
        setStateName(res.data.state);
        setCountryName(res.data.country);
        setIsdataLoaded(true);
        setFeaturedImage(res.data.featuredImage);
        setMaxAdults(res.data.maxAdults);
        setMaxChilds(res.data.maxChilds);
      });
  }, [history.location.pathname]);
  useEffect(() => {
    if (guestyListingId != '' && selectedDateForyear?.startDate != null && selectedDateForyear?.endDate != null) {
      axios.get(process.env.REACT_APP_BASE_URL + 'getBookedDates?listing_id=' + guestyListingId + '&checkin=' + selectedDateForyear?.startDate.format("YYYY-MM-DD") + '&checkout=' + selectedDateForyear?.endDate.format("YYYY-MM-DD"))
        .then(res => {
          //setSuccessMsg("Date is available");
          //setPriceBreakDown("Date is available");
          console.log(res.data);
          setavailableDates(res.data.response);
          CheckoutOnlyDates(res.data.checkout_only_date)
        })
    }
  }, [guestyListingId]);

  const isBlocked = (day: any) => {
    return availableDates?.includes(day.format('YYYY-MM-DD'));
  }
  const responseFacebook = (response_fb: any) => {
    //console.log(response_fb);
    //setData(response_fb);
    if (response_fb.accessToken) {
      axios.post(process.env.REACT_APP_BASE_URL + '/UserRegistrationbySocial', {
        name: response_fb.name,
        first_name: response_fb.first_name,
        last_name: response_fb.last_name,
        accessToken: response_fb.accessToken,
        email: response_fb.email,
        userID: response_fb.userID,
        picture: response_fb.picture.data.url,
      })
        .then(function (response) {
          console.log(response.data.response);
          if (response.data.status == 200) {
            setsuccess('Successfully register');
            //setSuccessMsg('Successfully register');
            //setDetails({first_name:"",last_name:"",email:"",password:""});
            localStorage.setItem("user_id", response.data.user_details.user_id);
            history.push("/");
          } else {
            setError(response.data.response);
            setErrorMsg(response.data.response);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      setLogin(true);
    } else {
      setLogin(false);
    }
  }
  const responseGoogle = (response_gmail: any) => {
    //console.log(response_gmail);
    if (response_gmail.accessToken) {
      axios.post(process.env.REACT_APP_BASE_URL + '/UserRegistrationbySocial', {
        name: response_gmail.profileObj.name,
        first_name: response_gmail.profileObj.givenName,
        last_name: response_gmail.profileObj.familyName,
        accessToken: response_gmail.accessToken,
        email: response_gmail.profileObj.email,
        userID: response_gmail.profileObj.googleId,
        picture: response_gmail.profileObj.imageUrl,
        platform: "gmail"
      })
        .then(function (response) {
          console.log(response.data.response);
          if (response.data.status == 200) {
            setsuccess('Successfully register');
            setSuccessMsg('Successfully register');
            //setDetails({first_name:"",last_name:"",email:"",password:""});
            localStorage.setItem("user_id", response.data.user_details.user_id);
            history.push("/");
          } else {
            setError(response.data.response);
            setErrorMsg(response.data.response);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      setLogin(true);
    } else {
      setLogin(false);
    }
  }
  const submitHandlerSignUp = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log(details);
    const isInvalid = validateInput();
    if (!isInvalid) {
      axios.post(process.env.REACT_APP_BASE_URL + 'UserLogin', details)
        .then(function (response) {
          console.log(response.data);
          if (response.data.status == 200) {
            localStorage.setItem("user_id", response.data.user_details.user_id);
            localStorage.setItem("display_name", response.data.user_details.display_name);
            localStorage.setItem("display_user_email", response.data.user_details.user_email);
            setsuccess(response.data.response);
            setDetails({ email: "", password: "" });
            //history.push("/");
            setIsOpenModalLoginSignUp(false);
            window.location.reload();
          } else {
            setError(response.data.response);
            setErrorMsg(response.data.response);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      setSuccessMsg('');
    }
  };
  const validateInput = () => {
    const fields = [
      {
        name: 'email',
        value: details.email,
        message: 'Email should not be blank.'
      },
      {
        name: 'password',
        value: details.password,
        message: 'Password should not be blank.'
      }
    ];
    const isNotFilled = fields.some(field => {
      if (field.value.trim() === '') {
        setErrorMsg(field.message);
        //field.name === 'email' ? emailRef.current.focus() : passwordRef.current.focus();
        return true;
      }
      setErrorMsg('');
      return false;
    });
    return isNotFilled;
  };
  const submitHandlerHostContact = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const isInvalid = validateHostContactInput();
    if (!isInvalid) {
      axios.post(process.env.REACT_APP_BASE_URL + 'guest_enquiries?listing_id=' + guestyListingId, detailsHostContact)
        .then(function (response) {
          if (response.data.status == 200) {
            setSuccessContact(response.data.response);
            setDetailsHostContact({
              host_contact_name: "",
              host_contact_email: "",
              host_contact_phone: "",
              host_contact_message: "",
              google_captcha: ""
            });
          } else {
            setErrorContact(response.data.response);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      setSuccessMsg('');
    }
  }
  const validateHostContactInput = () => {
    const fields = [
      {
        name: 'host_contact_name',
        value: detailsHostContact.host_contact_name,
        message: 'Name should not be blank.'
      },
      {
        name: 'host_contact_email',
        value: detailsHostContact.host_contact_email,
        message: 'Email should not be blank.'
      },
      {
        name: 'host_contact_phone',
        value: detailsHostContact.host_contact_phone,
        message: 'Phone should not be blank.'
      },
      {
        name: 'host_contact_message',
        value: detailsHostContact.host_contact_phone,
        message: 'Phone should not be blank.'
      },
      {
        id: 'google_captcha',
        value: detailsHostContact.google_captcha,
        message: 'Captcha should not be blank.'
      }
    ];
    const isNotFilled = fields.some(field => {
      if (field.value.trim() === '') {
        setErrorContact(field.message);
        //field.name === 'email' ? emailRef.current.focus() : passwordRef.current.focus();
        return true;
      }
      setErrorContact('');
      return false;
    });
    return isNotFilled;
  };

  function SetRecaptchaVal(value: any) {
    setDetailsHostContact({ ...detailsHostContact, google_captcha: value });
  }

  const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();




    if (selectedDate?.startDate != null && selectedDate?.endDate != null) {
      if (guestyListingId != '') {
        console.log({ checkInDetails, selectedDate });
        // setCheckInDetails({...checkInDetails,checkInCheckOutDate:selectedDate});
        axios.post(process.env.REACT_APP_BASE_URL + 'addToCart', {
          checkInCheckOutDate: { startDate: selectedDate?.startDate.format("YYYY-MM-DD"), endDate: selectedDate?.endDate.format("YYYY-MM-DD") },
          listing_id: guestyListingId,
          adults: adults,
          children: children,
          infants: infants,
          user_id: DefaultUserID
        }).then(res => {
          if (res.data.status == 401) {
            setCalendarMessage(res.data.response);
          }
          if (res.data.status == 200) {
            history.push("/checkout");
            history.go(0);
          }
        });
      }
    } else {
      setCalendarMessage("Start and end date required");
    }
  }

  const getDaySize = () => {
    if (windowSize.width <= 375) {
      return 34;
    }
    if (windowSize.width <= 500) {
      return undefined;
    }
    if (windowSize.width <= 1280) {
      return 48;
    }
    if (windowSize.width <= 1380) {
      return 40;
    }
    return 48;
  };
  function closeModalAmenities() {
    setIsOpenModalAmenities(false);
  }
  function openModalAmenities() {
    setIsOpenModalAmenities(true);
  }
  function closeModalReviews() {
    setisOpenModalReviews(false);
  }
  function openModalReviews() {
    setisOpenModalReviews(true);
  }
  function OpenModalLoginSignUp() {
    setIsOpenModalLoginSignUp(true);
    setIsOpenModalMobileBookingForm("hidden");
  }
  function closeModalLoginSignUp() {
    setIsOpenModalLoginSignUp(false);
  }
  function OpenModalHostContactUs() {
    setIsModalHostContactUs(true);
  }
  function closeModalHostContactUs() {
    setIsModalHostContactUs(false);
  }
  function openModalBookingForm() {
    setIsOpenModalMobileBookingForm("show");
  }

  function closeModalBookingForm() {
    setIsOpenModalMobileBookingForm("hidden");
  }

  function OpenModalLikeSave() {
    setIsOpenModalLikeShare(true);
  }
  function CloseModalLikeSave() {
    setIsOpenModalLikeShare(false);
  }
  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };
  const handleCloseModal = () => setIsOpen(false);


  const renderSectionTinyStories = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Tiny House Stories </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Hear it from our guests. Here is a list of stories from people that have experienced a stay on this property
          </span>
        </div>
        <SectionGridSimilarStories listing_id={guestyListingId} />
      </div>
    );
  };

 
  const renderMotalReviews = () => {
    return (
      <Transition appear show={isOpenModalReviews} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalReviews}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full">
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Reviews
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalReviews} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                    {allReviews.filter((_, i) => i < 200).map((stay, index) => (
                      <CommentListing key={index} className="py-8" data={stay} />
                    ))}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };
  

  const renderskeletonLoader = () => {
    return (
      <div>
        <div className="banner-loader-detal-page relative  gap-1 sm-full-1-img custom-loader mb-5 mt-5">
          <div className="p-3 bg-grey skeleton relative overflow-hidden w-1/2 mb-2"></div>
          <div className="bg-grey skeleton relative overflow-hidden w-1/100 h-5"></div>
        </div>
        <div className="banner-loader-detal-page relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2 sm-full-1-img custom-loader">
          <div className="bg-grey col-span-2 cursor-pointer h-400 overflow-hidden p-3 relative rounded-md row-span-3 skeleton sm:rounded-xl sm:row-span-2"></div>
          <div className="p-3 bg-grey skeleton relative rounded-md sm:rounded-xl overflow-hidden "></div>
          <div className="p-3 bg-grey skeleton relative rounded-md sm:rounded-xl overflow-hidden "></div>
          <div className="p-3 bg-grey skeleton relative rounded-md sm:rounded-xl overflow-hidden "></div>
          <div className="p-3 bg-grey skeleton relative rounded-md sm:rounded-xl overflow-hidden "></div>
        </div>
        <div className="banner-loader-detal-page custom-loader lg:flex-row gap-3 mt-5 mb-5 flex">
          <div className="w-70-loader  lg:w-3/5 xl:w-2/3 border-neutral-200  sm:rounded-2xl sm:border">
            <div className="card m-0">
              <div className="card-body">
                <div className="row mb-5">
                  <div className="col-12">
                    <div className="p-3 bg-grey skeleton"></div>
                    <div className="col-3 mt-1"><div className="p-2 bg-grey skeleton"></div></div></div></div></div></div></div>
          <div className="w-30-loader hidden lg:block flex-grow border-neutral-200 sm:rounded-2xl sm:border">
            <div className="card m-0">
              <div className="card-body">
                <div className="row mb-1"></div>
                <div className="row mb-4"><div className="col-12">
                  <div className="p-3 bg-grey skeleton"></div></div></div>
                <div className="mb-4">
                  <div className="row mb-3 justify-content-between">
                    <div className="col-4">
                      <div className="p-2 bg-grey skeleton"></div></div>
                    <div className="col-3">
                      <div className="p-2 bg-grey skeleton"></div></div></div>
                  <div className="row mb-3 justify-content-between">
                    <div className="col-4">
                      <div className="p-2 bg-grey skeleton"></div></div>
                    <div className="col-3 justify-content-between">
                      <div className="p-2 bg-grey skeleton"></div></div></div></div></div></div></div>
        </div>
      </div>
    );
  };

  const renderRatesDetails = () => {
    return (
      <div className="details-page-sub-header">
        <Collapsible trigger="Rate" open={true}>
          <div className="grid grid-cols-1 xl:grid-cols-1 gap-2 text-sm text-neutral-700 dark:text-neutral-300 max-win-320">
            <div>
              <div className="flex items-center space-x-3">
                <span className="d-flex-box flex justify-between"><span className="span-250"> Nightly</span> <b>{NightlyPrice}</b></span>
              </div>
              <div className="flex items-center space-x-3">
                 <span className="d-flex-box flex justify-between"><span className="span-250"> Weekends ({weekdaysname})</span>  <b>{WeekendsPrice}</b></span>
              </div>
              <div className="flex items-center space-x-3">
                  <span className="d-flex-box flex justify-between"><span className="span-250"> Weekly (7d+)</span>  <b>{WeeklyPrice}</b></span>
              </div>
              <div className="flex items-center space-x-3">
                 <span className=" d-flex-box flex justify-between"><span className="span-250">Monthly (30d+)</span>  <b>{MonthlyPrice}</b></span>
              </div>
            </div>
            <div>
              <div className="flex items-center space-x-3">
                 <span className="d-flex-box flex justify-between"><span className="span-250">Additional Guests</span> <b>{AdditionGuestPrice}</b></span>
              </div>
              <div className="flex items-center space-x-3">
                <span className="d-flex-box flex justify-between"><span className="span-250">Allow Additional Guests</span> <b>{AdditionGuestAllowed}</b></span>
              </div>
              <div className="flex items-center space-x-3">
                <span className="d-flex-box flex justify-between"><span className="span-250">Cleaning Fee</span> <b>{CleaningFee}</b></span>
              </div>
            </div>
          </div>
        </Collapsible>
      </div>
    )
  }
  const renderPropertiesTheSpace = () => {
    return (
      <div className="details-page-sub-header">
        <h3>DETAILS</h3>
        <Collapsible trigger="THE SPACE" open={true}>
          {descriptionspace}
        </Collapsible>
      </div>
    )
  }

  const renderPropertiesCheckinCheckout = () => {
    return (
      <div className="details-page-sub-header">
        <Collapsible trigger="CHECK-IN & CHECK-OUT" open={true}>
          <div className="flex items-center space-x-3  mb-2">
            <span className="d-flex-box flex justify-between"><span className="span-250">Check-in After</span>  <b>{defaultCheckInTime} </b></span>
          </div>
          <div className="flex items-center space-x-3  mb-2">
            <span className="d-flex-box flex justify-between"><span className="span-250">Check-out Before</span> <b>{defaultCheckOutTime}</b></span>
          </div>
        </Collapsible>
      </div>
    )
  }

  const renderDetailMap = () => {
    return (

      <div className="details-page-sub-header">
        <Collapsible trigger="LOCATIONS" open={true}>
          <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
            <div className="rounded-xl overflow-hidden">
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: 'AIzaSyCxywmWV_Bz1FH-NgAgc-wwcVcD0-c8XYQ',
                }}
                defaultZoom={mapzoom}
                yesIWantToUseGoogleMapApiInternals
                defaultCenter={mapcoordinates}
                center={mapcoordinates}
                zoom={mapzoom}
              >
                <LocationMarker lat={maplat} lng={maplng} />
              </GoogleMapReact>
            </div> </div>
        </Collapsible>
      </div>
    )
  }
  const renderGuestAccess = () => {
    return (
      <div className="details-page-sub-header">
        <Collapsible trigger="Guest access" open={false}>
          {descriptionaccess}
        </Collapsible>
      </div>
    )
  }
  const renderOtheThingstoNote = () => {
    return (
      <div className="details-page-sub-header">
        <Collapsible trigger="Other things to note" open={false}>
          {descriptionnotes}
        </Collapsible>
      </div>
    )
  }
  const renderNeighborhood = () => {
    return (
      <div className="details-page-sub-header">
        <Collapsible trigger="Neighborhood" open={false}>
          {descriptionneighborhood}
        </Collapsible>
      </div>
    )
  }
  const renderListingCommonQuestions = () => {
    return (
      <div className="details-page-sub-header">
        <h3>Common Questions</h3>
        <Collapsible trigger=" About the property's common questions" open={true}>
          <div className="grid grid-cols-1 xl:grid-cols-1 gap-2 text-sm text-neutral-700 dark:text-neutral-300 max-win-320">
            <div className="flex items-center space-x-3">
              <span className="d-flex-box flex justify-between "><span className="span-250">Pets allowed</span><b>{allowsPets}</b></span>
            </div>
            <div className="flex items-center space-x-3">
              <span className="d-flex-box flex justify-between "><span className="span-250"> Children allowed</span><b>{allowsChildren}</b></span>
            </div>
            <div className="flex items-center space-x-3">
              <span className="d-flex-box flex justify-between "><span className="span-250"> Mobile reception</span><b>{mobile_reception}</b></span>
            </div>
            <div className="flex items-center space-x-3">
              <span className="d-flex-box flex justify-between"><span className="span-250">Outdoor fire pit</span><b>{outdoor_fire_pit}</b></span>
            </div>
            <div className="flex items-center space-x-3">
              <span className="d-flex-box flex justify-between"><span className="span-250">Firewood provided free</span><b>{firewood_provided_free}</b></span>
            </div>
          </div>
        </Collapsible>
      </div>
    )
  }

  const renderHowIsolatedTinyHouse = () => {
    return (
      <div className="details-page-sub-header">
        <Collapsible trigger="How isolated is the tiny house?" open={false}>
          How isolated is the tiny house?
        </Collapsible>
      </div>
    )
  }


  const renderlistingTerms = () => {
    return (
      <div className="details-page-sub-header">
        <h3>Terms & rules</h3>
        <Collapsible trigger="CANCELLATION POLICY" open={true}>

          <div>
            <b>
              About the property's terms and rules
            </b>
            <p>{cancellation_policy}</p>
          </div>
        </Collapsible>
      </div>
    );
  };
  const renderFeaturesAmenities = () => {
    return (
      <div className="details-page-sub-header">
        <h3>FEATURES AND AMENITIES</h3>
        <Collapsible trigger="THE SPACE" open={true}>
          <div className="grid grid-cols-2 xl:grid-cols-2 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
            {amenities.filter((_, i) => i < 12).map((item) => (
              <div key={item.name} className="flex items-center space-x-3">
                <i className={`text-3xl all-cons ${item.icon}`}></i>
                <span className=" ">{item.name}</span>
              </div>
            ))}
          </div>
        </Collapsible>
      </div>
    )
  }
  const renderTinyStories = () => {
    return (
      <div className="details-page-sub-header">
        <h3>Tiny House Stories</h3>
        <Collapsible trigger="Hear it from our guests. Here is a list of stories from people that have experienced a stay on this property" open={true}>
          <SectionGridSimilarStories listing_id={guestyListingId} />
        </Collapsible>
      </div>
    )
  }


  const renderReviewsHouse = () => {
    return (
      <div className="details-page-sub-header">
        <h3>REVIEWS</h3>
        <Collapsible trigger="100% RECOMMENDED - 51 REVIEWS" open={true}>
          <div>
            <div className="bgcolor divide-y divide-neutral-100 dark:divide-neutral-800 grid grid-cols-1 gap-4 md:gap-4 sm:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1">
              {allReviews.filter((_, i) => i < 5).map((stay, index) => (
                <CommentListing key={index} className="py-8" data={stay} />
              ))}


              {renderMotalReviews()}
            </div>
            <div className="pt-4 text-center">
              <ButtonSecondary onClick={openModalReviews}>View all {totalReviews} reviews</ButtonSecondary>
            </div>
          </div>
        </Collapsible>
      </div>
    )
  }


  const renderSection5 = () => {
    return (
      <div className="details-page-sub-header listingSectionCard">
        <div className="listingSection__wrap">
          {/* HEADING */}
          <h3>HOST</h3>
          {/* host */}
          <div className="flex items-center space-x-4">
            <Avatar
              hasChecked
              hasCheckedClass="w-4 h-4 -top-0.5 right-0.5"
              sizeClass="h-14 w-14"
              radius="rounded-full"
              avatarImage={imgUrl1.toString()}
            />
            <div>
              <a className="block text-xl font-medium" href={void (0)}>
                {authorname}
              </a>
              <div className="mt-1.5 flex items-center text-sm text-neutral-500 dark:text-neutral-400">
                <StartRating reviewCount={totalhosted} point={5} isHost={true} />
                <span className="mx-2">·</span>
                {/* <span> 12 places</span> */}
              </div>
            </div>
          </div>

          {/* desc */}
          <span className="block text-neutral-6000 dark:text-neutral-300">
            {hostinfo}
          </span>

          {/* info */}
          {/* <div className="block text-neutral-500 dark:text-neutral-400 space-y-2.5">
        <div className="flex items-center space-x-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
          <span>Joined in March 2016</span>
        </div> 
      </div> */}

          {/* == */}
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div>
            {/* <ButtonSecondary href="##">See host profile</ButtonSecondary>*/}
          </div>
        </div>
      </div>
    );
  };

const renderFuaturesTinyHouses = () => {
  return (
    <div className="pt-12 w-full">
    <div className="flex gap-8 flex-full-sm">
    <div className="w-full lg:w-3/5 sm-full-100">
        <img className="rounded-2xl " src="https://pictures.tinyaway.com/filters:format(jpg)/900x620/Listings/Images/2024/05/1/gwcisxhbjhxgshvrlxbf1714967317336.jpg"></img>
      </div>
      <div className="w-full lg:w-3/5 sm-full-100">
      <div className="details-page-sub-header details-gaps-4"> 
        <Collapsible trigger=" FEATURES OF OUR TINY HOUSES" open={true}>
          <div>
          <p>No matter the location of tiny house you are interested in renting from us, you will find the following features in most of the tiny houses, except for a few: </p>
<p>A kitchenette with all you need to prepare your light meals, including a cookware, portable cooker, cooking utensils, dishes & silverware, cutlery, microwave, and a mini-fridge. A bathroom that operates in an ecologically sustainable principle, comprising of a waterless environmentally friendly compost toilet, shower (gas heated for hot showers), 
</p> <p>hand basin. </p>
<p>Air conditioning A comfy queen sized bed with fresh bed sheets. </p>
<p>A lounge area to chill and enjoy nature at its best. </p>
<p>Here at Tiny Away, we also have pet-friendly cabins for guests who would love a vacation with their pets. We encourage guests booking with their pets to inform the host. Our tiny houses are built to give you the best comfort and convenience while escaping from the hectic urban life.</p>
            </div>  
        </Collapsible>

        <Collapsible trigger="WHY TINY HOUSE GETAWAY" open={false}>
          <div>
          <p>No matter the location of tiny house you are interested in renting from us, you will find the following features in most of the tiny houses, except for a few: </p>
<p>A kitchenette with all you need to prepare your light meals, including a cookware, portable cooker, cooking utensils, dishes & silverware, cutlery, microwave, and a mini-fridge. A bathroom that operates in an ecologically sustainable principle, comprising of a waterless environmentally friendly compost toilet, shower (gas heated for hot showers), 
</p> <p>hand basin. </p>
<p>Air conditioning A comfy queen sized bed with fresh bed sheets. </p>
<p>A lounge area to chill and enjoy nature at its best. </p>
<p>Here at Tiny Away, we also have pet-friendly cabins for guests who would love a vacation with their pets. We encourage guests booking with their pets to inform the host. Our tiny houses are built to give you the best comfort and convenience while escaping from the hectic urban life.</p>
            </div>  
        </Collapsible>


        <Collapsible trigger="WHY CHOOSE US" open={false}>
          <div>
          <p>No matter the location of tiny house you are interested in renting from us, you will find the following features in most of the tiny houses, except for a few: </p>
<p>A kitchenette with all you need to prepare your light meals, including a cookware, portable cooker, cooking utensils, dishes & silverware, cutlery, microwave, and a mini-fridge. A bathroom that operates in an ecologically sustainable principle, comprising of a waterless environmentally friendly compost toilet, shower (gas heated for hot showers), 
</p> <p>hand basin. </p>
<p>Air conditioning A comfy queen sized bed with fresh bed sheets. </p>
<p>A lounge area to chill and enjoy nature at its best. </p>
<p>Here at Tiny Away, we also have pet-friendly cabins for guests who would love a vacation with their pets. We encourage guests booking with their pets to inform the host. Our tiny houses are built to give you the best comfort and convenience while escaping from the hectic urban life.</p>
            </div>  
        </Collapsible>


      </div>
      </div>
      
    </div>

  </div>
  )
}

const renderGalleryList = () => {
  return(
    <div className="gallery-list-headding">
      <div><h3>gallery</h3>  </div>
    <div className="gallery-list">
      <div><img src="https://pictures.tinyaway.com/filters:format(jpg)/960x920/Listings/Images/2024/05/1/gwcisxhbjhxgshvrlxbf1714967317336.jpg"></img></div>
      <div><img src="https://pictures.tinyaway.com/filters:format(jpg)/960x920/Listings/Images/2024/05/1/gwcisxhbjhxgshvrlxbf1714967317336.jpg"></img></div>
      <div><img src="https://pictures.tinyaway.com/filters:format(jpg)/960x920/Listings/Images/2024/05/1/gwcisxhbjhxgshvrlxbf1714967317336.jpg"></img></div>
    </div>
    </div>
  )
}


  return (
    <div className={`nc-ListingStayDetailPage  ${className}`} data-nc-id="ListingStayDetailPage">
      <Helmet title={metatitle}></Helmet>
      <>

        {isdataLoaded ?
          <div className="content-page-style">
            <header className="w-100">

              <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2 sm-full-1-img">
                <div
                  className="col-span-2 row-span-3 sm:row-span-2 relative  overflow-hidden cursor-pointer"
                  onClick={() => handleOpenModal(0)}
                >
                  <NcImage
                    containerClassName="absolute inset-0"
                    className="object-cover w-full h-full"
                    src={PHOTOS[0]}
                    prevImageHorizontal
                  />
                  <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
                </div>
                {PHOTOS.filter((_, i) => i >= 1 && i < 5).map((item, index) => (
                  <div
                    key={index}
                    className={`relative  overflow-hidden ${index >= 3 ? "hidden sm:block" : ""
                      }`}
                  >
                    <NcImage
                      containerClassName="aspect-w-4 aspect-h-6 sm:aspect-w-6 sm:aspect-h-4"
                      className="object-cover w-full h-full"
                      src={item || ""}
                      prevImageHorizontal
                    />

                    {/* OVERLAY */}
                    <div
                      className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                      onClick={() => handleOpenModal(index + 1)}
                    />
                  </div>
                ))}
                <div className="absolute hidden md:flex md:items-center md:justify-center right-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10" onClick={() => handleOpenModal(0)}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
                  </svg>
                  <span className="ml-2 text-neutral-800 text-sm font-medium">
                    Show all photos
                  </span>
                </div>
              </div>
            </header>
            <div className="container mt-5 mb-5">
              <div className="flex flex-full-sm">
                <div className="w-full lg:w-3/5 sm-full-100 xl:w-2/3 space-y-1 lg:space-y-0 lg:pr-10">
                  <img src="https://pictures.tinyaway.com/filters:format(jpg)/960x720/Listings/Images/2024/05/1/gwcisxhbjhxgshvrlxbf1714967317336.jpg" />
                </div>

                <div className="w-full lg:w-3/5 sm-full-100 xl:w-2/3 space-y-5 lg:space-y-5 lg:pr-5">
                  <div className="relative grid grid-cols-4 sm:grid-cols-1 gap-5 sm:gap-2 mt-15 mb-15 sm-grid-cols-5">
                    <div className="flex justify-between items-center">
                      <div>
                        <span>  <a href="#scroll_to_location" className="ml-1">{listingaddress}</a> </span>
                      </div>

                    </div>
                    <div className="nc-Section-Heading">
                      <h2>{title}</h2>
                    </div>
                  </div>

                  <div className="flex flex-wrap gap-4 w-100">

                    <div className="w-full" />

                    {/* 6 */}
                    <div className="flex items-center justify-between gap-2 flex-wrap">

                      <ButtonPrimary className="py-1.5">FEATURED</ButtonPrimary>
                      <ButtonPrimary className="py-1.5">PET-FRIENDLY</ButtonPrimary>

                      <div className="flex items-center space-x-1 ">
                        <i className=" las la-user text-2xl "></i>
                        <span className="">
                          {maxguest} <span className="hidden sm:inline-block">guest</span>
                        </span>
                      </div>
                      <div className="flex items-center space-x-3">
                        <i className=" las la-bed text-2xl"></i>
                        <span className=" ">
                          {beds} <span className="hidden sm:inline-block">bed</span>
                        </span>
                      </div>
                      <div className="flex items-center space-x-3">
                        <i className=" las la-bath text-2xl"></i>
                        <span className=" ">
                          {bath} <span className="hidden sm:inline-block">bath</span>
                        </span>
                      </div>
                      <div className="flex items-center space-x-3">
                        <i className=" las la-door-open text-2xl"></i>
                        <span className=" ">
                          {bedrooms} <span className="hidden sm:inline-block">bedroom</span>
                        </span>
                      </div>
                    </div>
                    <div className="text-listing-page">
                      <div className="flex p-0">Take your weekend getaways here at the Hilltop, the perfect farm stay Victoria retreat for vacationers to experience the unhurried backcountry lifestyle of our family-operated farm. The ideal off-grid retreat close to Broadford and Kilmore's exciting attractions and activities that makes you come back to on their weekend trips.</div>
                      <div className="flex p-0">Just an hour's drive from Melbourne past Parkville, Thomastown, and Beveridge VIC along the way.</div>

                    </div>
                    <div className="flex justify-between items-center flex-border-btn">

                      <LikeSaveBtns className="btn" onChange={() => setIsOpenModalLikeShare(true)} />
                      <LikeSaveBtns onChange={() => setIsOpenModalLikeShare(true)} />
                      <LikeSaveBtns onChange={() => setIsOpenModalLikeShare(true)} />
                    </div>

                  </div>
                </div>

              </div>
            </div>

            <div className="w-full">

              <img src="https://pictures.tinyaway.com/filters:format(jpg)/1920x700/Listings/Images/2024/05/1/gwcisxhbjhxgshvrlxbf1714967317336.jpg" />
            </div>




            <div className="container mt-mb-50">
              <div className="flex gap-8 flex-full-sm">
                <div className="w-full lg:w-3/5 sm-full-100">
                  {renderPropertiesTheSpace()}
                  {renderPropertiesCheckinCheckout()}
                  {renderRatesDetails()}
                  {renderDetailMap()}
                  <div className="list-blog-mb-0">
                    {renderGuestAccess()}
                    {renderOtheThingstoNote()}
                    {renderNeighborhood()}

                  </div>
                  {renderListingCommonQuestions()}
                  {renderHowIsolatedTinyHouse()}
                  {renderlistingTerms()}

                </div>




                <div className="w-full lg:w-3/5 sm-full-100">
                  {renderFeaturesAmenities()}
                  {renderTinyStories()}
                  {renderReviewsHouse()}
                </div>

              </div>
            </div>

            <div className="w-full">
              <div className="flex gap-0 flex-full-sm">
                <div className="w-full lg:w-3/5 sm-full-100">
                  {renderSection5()}
                </div>
                <div className="w-full lg:w-3/5 sm-full-100">
                  <img src="https://pictures.tinyaway.com/filters:format(jpg)/900x470/Listings/Images/2024/05/1/gwcisxhbjhxgshvrlxbf1714967317336.jpg"></img>
                </div>
              </div>

            </div>
           {renderGalleryList()}
          </div>
          :
          <header className="container 2xl:px-14 rounded-md sm:rounded-xl">
            {renderskeletonLoader()}
          </header>
        }
        {isdataLoaded ?
          <ModalPhotos
            imgs={PHOTOS}
            isOpen={isOpen}
            onClose={handleCloseModal}
            initFocus={openFocusIndex}
          />
          : ""}
      </>
      {isdataLoaded ?
        <main className="container mt-0 flex ">
          {/* CONTENT */}

          {/* SIDEBAR */}
          {/* <div className={`${isOpenModalMobileBookingForm} lg:block flex-grow phone-show-detailpage`}>
            <div className="close-icon-phone">
              <a href={void (0)} onClick={closeModalBookingForm}><svg xmlns="http://www.w3.org/2000/svg" height="512" viewBox="0 0 512 512" width="512"><title /><path d="M289.94,256l95-95A24,24,0,0,0,351,127l-95,95-95-95A24,24,0,0,0,127,161l95,95-95,95A24,24,0,1,0,161,385l95-95,95,95A24,24,0,0,0,385,351Z" /></svg>
              </a>
            </div>
            <div className="sticky top-24 payment-form-ms-size">{renderSidebar()}</div>
          </div> */}
        </main>
        : ""}
      {/* STICKY FOOTER MOBILE */}
      {!isPreviewMode && (
        <div className="block lg:hidden fixed bottom-0 inset-x-0 py-4 bg-white text-neutral-900 border-t border-neutral-200 z-20">
          <div className="container flex items-center justify-between">
            <span className="text-2xl font-semibold">
              <sup>{currency_code}</sup>{price}
              <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
                /night
              </span>
            </span>
            <ButtonPrimary href={void (0)} onClick={openModalBookingForm}>Add to cart</ButtonPrimary>
          </div>
        </div>
      )}
      {/* OTHER SECTION */}
      {!isPreviewMode && ( 
        <div className="container py-24 lg:py-32 gap-8 bg-color-gry">
          {/* SECTION 1 */}
          <div className="relative py-16">
            
            {maplatSimilar != "" ?
              <SectionGridSimilarListing lattitude={maplatSimilar} longitude={maplngSimilar} excludeListing={guestyListingId} />
              : ''
            }
            
          </div>
          {/* SECTION */}
          {/* <SectionSubscribe2 className="pt-24 lg:pt-32" />*/}
        </div>
        
      )}
 <div className="container">
{renderFuaturesTinyHouses()}
</div>
<SectionSubscribe2 />
    </div>
  );
};
export default ListingStayDetailPage;
