import React, { useEffect, useState } from "react";
import {
  AnchorDirectionShape,
  DateRangePicker,
  FocusedInputShape,
} from "react-dates";
import { DateRage } from "./StaySearchForm";
import { FC } from "react";
import ClearDataButton from "./ClearDataButton";
import useWindowSize from "hooks/useWindowResize";
import moment from "moment";

type Fields = "checkIn" | "checkOut";

export interface StayDatesRangeInputProps {
  defaultValue: DateRage;
  defaultFocus?: FocusedInputShape | null;
  onChange?: (data: DateRage) => void;
  onFocusChange?: (focus: FocusedInputShape | null) => void;
  fieldClassName?: string;
  wrapClassName?: string;
  numberOfMonths?: number;
  anchorDirection?: AnchorDirectionShape;
  guetyListingId?: string;
  availableDates: string;
  highlightedDates?: string;
}

const StayDatesRangeInput: FC<StayDatesRangeInputProps> = ({
  defaultValue,
  onChange,
  defaultFocus = null,
  onFocusChange,
  fieldClassName = "[ nc-hero-field-padding ]",
  wrapClassName = "divide-y divide-neutral-200 dark:divide-neutral-700 lg:divide-y-0 md:border-l md:border-r border-neutral-200 lg:border-none",
  numberOfMonths,
  anchorDirection,
  guetyListingId,
  availableDates,
  highlightedDates
}) => {
  const [focusedInput, setFocusedInput] = useState(defaultFocus);
  const [stateDate, setStateDate] = useState(defaultValue);
 
  const isBlocked = (day:any) => {
      return availableDates.includes(day.format('YYYY-MM-DD'));
  }

  const isDayHighlighted = (day:any) => {    
    return highlightedDates?.includes(day.format('YYYY-MM-DD')) ?? false;
  }
  
  const isOutsideRange = (day:any) => {
    return day.isBefore(moment().subtract(1, "days"));
  }
  //console.log(guetyListingId);
  const windowSize = useWindowSize();

  useEffect(() => {
    setStateDate(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    setFocusedInput(defaultFocus);
  }, [defaultFocus]);

  useEffect(() => {
    if (onChange) {
      onChange(stateDate);
    }
  }, [stateDate]);

  const handleClearData = () => {
    setStateDate((date) => ({ ...date, startDate: null }));
    setStateDate((date) => ({ ...date, endDate: null }));
  };
  const handleDateFocusChange = (focus: FocusedInputShape | null) => {
    setFocusedInput(focus);
    onFocusChange && onFocusChange(focus);
  };

  const renderInputCheckInDate = () => {
    const focused = focusedInput === "startDate";
    return (
      <div
        className={`justify-center h-14 border-r relative flex flex-1 ${fieldClassName} flex-shrink-0 items-center space-x-3 cursor-pointer ${
          focused ? "shadow-2xl rounded-full dark:bg-neutral-800" : " "
        }`}
        onClick={() => handleDateFocusChange("startDate")}
      >
         
         <span className="block xl:text-sm font-semibold">
          {stateDate.startDate
            ? stateDate.startDate.format("YYYY-MM-DD")
            : "Add dates"}
             {stateDate.endDate
              ?" - " + stateDate.endDate.format("YYYY-MM-DD")
              : ""}
        </span>
          {stateDate.startDate &&  ( //focused &&
            <ClearDataButton onClick={() => handleClearData()} />
          )}
        {/* <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
            {"Check in - Check out"}
          </span> */}
        <i className="ml-3 las la-arrow-right text-xl"></i>
      </div>
    );
  };

  const renderInputCheckOutDate = () => {
    const focused = focusedInput === "endDate";
    return (
      <div
        className={`relative flex flex-1 h-14 border-r ${fieldClassName} flex-shrink-0 items-center space-x-3 cursor-pointer ${
          focused ? "shadow-2xl rounded-full dark:bg-neutral-800" : " "
        }`}
        onClick={() => handleDateFocusChange("endDate")}
      >
        
        <div className="flex-grow">
          <span className="block xl:text-sm font-semibold">
            {stateDate.endDate
              ? stateDate.endDate.format("YYYY-MM-DD")
              : "Depart"}
          </span>
           
          {/* {stateDate.endDate &&  ( //focused &&
            <ClearDataButton onClick={() => handleClearData("checkOut")} />
          )} */}
        </div>
        <i className="ml-3 las la-arrow-right text-xl"></i>
      </div>
    );
  };

  return (
    <div className="relative flex-shrink-0 flex z-10 [ lg:nc-flex-2 ] border-end space-x-3">
      <div className="absolute inset-x-0 bottom-0">
        <DateRangePicker
          startDate={stateDate.startDate}
          endDate={stateDate.endDate}
          focusedInput={focusedInput}
          onDatesChange={(date) => setStateDate(date)}
          onFocusChange={handleDateFocusChange}
          numberOfMonths={windowSize.width < 1280 ? 1 : 2}
          startDateId={"arrive"}
          endDateId={"depart"}
          daySize={windowSize.width > 500 ? 56 : undefined}
          orientation={"horizontal"}
          showClearDates
          noBorder
          keepOpenOnDateSelect={false}
          hideKeyboardShortcutsPanel
          anchorDirection={anchorDirection}
          displayFormat="YYYY-MM-DD"
          isDayBlocked={isBlocked}
          isDayHighlighted={isDayHighlighted}
          // minimumNights={2}
        />
      </div>

      <div
        className={`flex flex-col lg:flex-row lg:items-center w-full flex-shrink-0 relative  ${wrapClassName}`}
      >
        {renderInputCheckInDate()}

        {/* {renderInputCheckOutDate()} */}
      </div>
    </div>
  );
};

export default StayDatesRangeInput;
