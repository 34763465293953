import React, { FC } from "react";
import rightImgPng from "images/TINY-AWAY-QUIET-LAKE-HOUSE-SJL-Photography-00006-667x500.jpg";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";

export interface SectionOurFeaturesProps {
  className?: string;
  rightImg?: string;
  type?: "type1" | "type2";
}

const SectionOurFeatures: FC<SectionOurFeaturesProps> = ({
  className = "lg:py-14",
  rightImg = rightImgPng,
  type = "type1",
}) => {
  return (
    <div
      className={`nc-SectionOurFeatures relative flex flex-col items-center ${
        type === "type1" ? "lg:flex-row" : "lg:flex-row-reverse"
      } ${className}`}
      data-nc-id="SectionOurFeatures"
    >
      <div className="flex-grow">
        <NcImage src={rightImg} />
      </div>
      <div
        className={`max-w-2xl flex-shrink-0 mt-10 lg:mt-0 lg:w-2/5 ${
          type === "type1" ? "lg:pl-16" : "lg:pr-16"
        }`}
      >
        
        <h2 className="font-semibold text-4xl mt-5">Have you booked your well deserved getaway?</h2>

        <ul className="space-y-10 mt-6 mb-li-sp">
          <li className="">
          
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
              Many reports have pointed out that staycations will be the new vacation norm for most of us. Besides that, domestic travellers will prefer accommodation that have larger open spaces and are not as crowded.
            </span>
          </li>
          <li className="">
          
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
              Guests will also want to be outside more, rather than being confined indoors. And close-to-nature experiences will be high on their priority lists. Integrating the concept of the trending tiny house movement with ecotourism, Tiny Away helps guests stay close to nature, yet still be able to enjoy the comforts a normal home can afford. Would you like to take the opportunity to experience tiny house living? Tiny house rentals. Experiential travel.
            </span>
          </li>
          <li className="">
           
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
              In Tiny Away, we are planting trees every month. Let’s do this together. <a href="/onetreeplanted" className="color-link">Read more.</a>
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SectionOurFeatures;
