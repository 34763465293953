import React, { FC } from "react";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionGridHasMap from "./SectionGridHasMap";
import { Helmet } from "react-helmet";

export interface ListingStayMapPageProps {
  className?: string;
}

const ListingStayMapPage: FC<ListingStayMapPageProps> = ({
  className = "",
}) => {
  return (
    <div
      className={`nc-ListingStayMapPage relative bg-color-gry ${className}`}
      data-nc-id="ListingStayMapPage"
    >
      
      <Helmet>
        <title>Tiny Houses Mapview - Tiny Away | Weekend Getaway | Rent Tiny House</title>
      </Helmet>
      <BgGlassmorphism />
      {/* SECTION HERO */}
      <div className="container relative container-serch-form-full ">
      <div className="flex justify-start pt-12">
        <div className="play-stay-icon">
          <span className="btn-con"></span>
          <span>PLACES TO STAY</span>
        </div>
        <div className="m-auto w-5/6"><SectionHeroArchivePage currentPage="Stay" currentTab="Stay" /></div>
        </div>  
      </div>
      {/* SECTION */}
      <div className="container pb-24 lg:pb-32 2xl:pl-10 xl:pr-0 xl:max-w-none">
        <SectionGridHasMap />
      </div>
      <div className="container overflow-hidden">
       
      </div>
    </div>
  );
};
export default ListingStayMapPage;
